// DashboardVendedor.jsx

import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { Outlet, useParams } from "react-router-dom";
import Navbar from "../../components/Nav/Navbar";
import ModalUbicacion from "../../components/ModalUbicacion/ModalUbicacion";
import { useUser } from "../../context/UserContext";

const { Content } = Layout;

const ClienteView = () => {
  const { id } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const { userLocation, setUserLocationCurrent } = useUser();

  const handleModalOk = (ubicacion) => {
    // Aquí puedes manejar la ubicación seleccionada, por ejemplo, guardarla en el estado de tu componente padre o realizar alguna acción con ella.
    setUserLocationCurrent({ latitude: ubicacion.lat, longitude: ubicacion.lng }); // Actualiza el estado del usuario con la nueva ubicación

    setModalVisible(false); // Oculta el modal después de que se ha seleccionado una ubicación
  };
  const handleModalCancel = () => {
    setModalVisible(false); // Oculta el modal si se cancela
  };


  // useEffect para mostrar el modal si userData.ubicacion está vacío o no existe
  useEffect(() => {
    // Agregar un retraso de al menos 3 segundos antes de mostrar el modal
    const timeout = setTimeout(() => {
      if (!userLocation) {
        setModalVisible(true);
      } else {
        setModalVisible(false);
      }
    }, 2000); // 3000 milisegundos = 3 segundos

    // Limpiar el timeout en el cleanup de useEffect
    return () => clearTimeout(timeout);
  }, [userLocation]);

  return (
    <Layout>
      <Content
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Navbar />
        <div style={{ marginTop: "78px", flex: 1, overflow: "auto" }}>
          <ModalUbicacion
            visible={modalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
          />
          <Outlet id={id} />
        </div>
      </Content>
    </Layout>
  );
};

export default ClienteView;
