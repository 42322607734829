import React, { useEffect, useState } from "react";
import { Table, Button, Space, message, Popconfirm, Input } from "antd";
import {
  updateDoc,
  doc,
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const PreguntasAdmin = ({ revisado }) => {

  const [preguntas, setPreguntas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  // Filtrar categorías según el término de búsqueda
  const filteredPreguntas = preguntas.filter((pregunta) =>
    pregunta.comentarios.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchPreguntas = async () => {
    try {
      const db = getFirestore();
      const preguntasCollection = collection(db, "apoyoNuevaCuenta");
      const querySnapshot = await getDocs(
        query(
          preguntasCollection,
          where("revisado", "==", revisado)
        )
      );

      const PreguntasData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPreguntas(PreguntasData);
    } catch (error) {
      console.error("Error al obtener Preguntas:", error.message);
    }
  };

  useEffect(() => {
    fetchPreguntas();
  }, [revisado]);

  const handlerevisadoChange = async (userId, revisado) => {
    try {
      const db = getFirestore();
      const userRef = doc(db, "apoyoNuevaCuenta", userId);
      await updateDoc(userRef, { revisado: !revisado });
      fetchPreguntas();
      message.success("Estado de activación cambiado correctamente");
    } catch (error) {
      console.error("Error al cambiar el estado de activación:", error.message);
      message.error("Error al cambiar el estado de activación");
    }
  };

  const columns = [
    {
      title: "Comentarios",
      dataIndex: "comentarios",
      key: "comentarios",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Telefono",
      dataIndex: "telefono",
      key: "telefono",
    },
    {
      title: "revisado",
      dataIndex: "revisado",
      key: "revisado",
      render: (revisado, record) => (
        <span>
          {revisado ? "Ya revisado" : "Pendiente de contactar"}
          <Popconfirm
            title={`¿Estás seguro de indicar ${revisado ? "volver a activar" : "que ya se contacto "}?`}
            onConfirm={() => handlerevisadoChange(record.id, revisado)}
            okText="Sí"
            cancelText="No"
          >
            <Button type="link">{revisado ? "Indicar pendiente" : "Revisar"}</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <h1>Preguntas para ser proveedor</h1>
        <Input.Search
          placeholder="Buscar preguntas"
          onSearch={handleSearch}
          style={{ width: 400, marginBottom: 16 }}
        />

        <Link to={`/admin/preguntas/${revisado ? "" : "respondidas"}`}>
          <Button
            type="primary"
            danger={!revisado}
            icon={revisado ? <SmileOutlined /> : <FrownOutlined /> }
            style={{ marginBottom: 16 }}
          >
            {revisado ? "Ver pendientes de contactar" : "Ir a ver ya revisados"}
          </Button>
        </Link>
      </div>

      <Table
        dataSource={filteredPreguntas}
        columns={columns}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default PreguntasAdmin;
