import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../firebaseConfig";


 export const fetchOrdersAndPendingMessages = async (userUid) => {
    const ordersCollection = collection(db, "pedidos");
    const userOrdersQuery = query(
        ordersCollection,
        where("usuarioUid", "==", userUid),
        orderBy("fechaCreacion", "desc")
    );

    const ordersSnapshot = await getDocs(userOrdersQuery);
    const ordersData = ordersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));

    let totalMessages = 0;

    for (const order of ordersData) {
        const subpedidosCollection = collection(db, "subpedidos");
        const subpedidosQuery = query(
            subpedidosCollection,
            where("pedidoId", "==", order.id)
        );
        const subpedidosSnapshot = await getDocs(subpedidosQuery);

        for (const subpedidoDoc of subpedidosSnapshot.docs) {
            const messagesCollection = collection(
                db,
                "subpedidos",
                subpedidoDoc.id,
                "messages"
            );
            const messagesQuery = query(
                messagesCollection,
                where("status", "==", "approved"),
                where("senderId", "!=", userUid),
                where("read", "==", false)
            );
            const messagesSnapshot = await getDocs(messagesQuery);
            totalMessages += messagesSnapshot.size;
        }
    }

    return { ordersData, totalMessages }; // Devuelve las órdenes y el conteo total de mensajes
};


 export const fetchOrdersAndPendingMessagesVendedor = async (userUid) => {



    let totalMessages = 0;


        const subpedidosCollection = collection(db, "subpedidos");
        const subpedidosQuery = query(
            subpedidosCollection,
            where("vendedorUid", "==", userUid),
        );
        const subpedidosSnapshot = await getDocs(subpedidosQuery);

        for (const subpedidoDoc of subpedidosSnapshot.docs) {
            const messagesCollection = collection(
                db,
                "subpedidos",
                subpedidoDoc.id,
                "messages"
            );
            const messagesQuery = query(
                messagesCollection,
                where("status", "==", "approved"),
                where("senderId", "!=", userUid),
                where("read", "==", false)
            );
            const messagesSnapshot = await getDocs(messagesQuery);
            totalMessages += messagesSnapshot.size;
        }
    

    return { totalMessages }; // Devuelve las órdenes y el conteo total de mensajes
};



