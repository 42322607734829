import React from "react";
import { Layout, Row, Col, Form, Input, Button, message } from "antd";
import { MailOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import incrementarventas from "../../assets/incrementaventas.png";
const { Footer } = Layout;

const CustomFooter = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      // Agregar los valores del formulario a Firestore

      const data = {
        comentarios: values.comentarios,
        email: values.email,
        telefono: values.telefono,
        revisado: false,
      };
      await addDoc(collection(db, "apoyoNuevaCuenta"), data);
      message.success(
        "¡Consulta enviada con éxito, un asesor se comunicara contigo!"
      );
      form.resetFields(); // Resetea los campos después de enviar
    } catch (error) {
      console.error("Error al enviar la consulta: ", error);
      message.error("Hubo un error al enviar la consulta. Intenta de nuevo.");
    }
  };

  return (
    <Footer
      style={{
        textAlign: "center",
        background: "#1b1a33",
        color: "white",
        marginTop: "10px",
      }}
    >
      <Row gutter={[16, 16]} justify="center">
        <Col span={24}>
          <h2 style={{ color: "#fff", marginBottom: "10px" }}>
            ¿Quieres ser un proveedor en Blizu?
          </h2>
          <p style={{ color: "#fff", marginBottom: "30px" }}>
            Descubre los beneficios que tendrás al publicar tus servicios en
            Blizu, agrega tus datos para que un ascesor te contacte
          </p>
          <Row gutter={[16, 16]} justify="center">
            <Col span={12} style={{textAlign: "right"}}>
              <img
                src={incrementarventas}
                alt="Descripción de la imagen"
                style={{ width: "350px", height: "auto", textAlign: "right" }}
              />
            </Col>

            <Col span={12}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                style={{ maxWidth: "600px", width: "100%", textAlign: "left" }}
              >
                <Form.Item
                  name="email"
                  label={
                    <span style={{ color: "#fff" }}>
                      Correo
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Por favor ingresa un correo válido.",
                    },
                  ]}
                >
                  <Input
                    placeholder="tu-email@ejemplo.com"
                    style={{ borderRadius: "5px" }}
                  />
                </Form.Item>
                <Form.Item
                  name="telefono"
                  label={
                    <span style={{ color: "#fff" }}>
                      Telefono
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      type: "telefono",
                      message: "Por favor ingresa un telefono válido.",
                    },
                  ]}
                >
                  <Input
                    placeholder="+52 123 456 7890"
                    style={{ borderRadius: "5px" }}
                  />
                </Form.Item>
                <Form.Item
                  style={{ color: "#fff" }}
                  name="comentarios"
                  label={<span style={{ color: "#fff" }}>Comentarios</span>}
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa tus comentarios.",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="Escribe tus comentarios aquí sobre tus dudas..."
                    style={{ borderRadius: "5px" }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      borderRadius: "5px",
                      background: "#fff",
                      color: "#1dbf73",
                    }}
                  >
                    Enviar
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <strong>
            <a href="terminos-condiciones" style={{ color: "white" }}>
              Términos y condiciones
            </a>
          </strong>
          <span style={{ margin: "0 10px" }}>|</span>
          <strong>
            <a href="declaracion-privacidad" style={{ color: "white" }}>
              Privacidad y confidencialidad
            </a>
          </strong>
          <span style={{ margin: "0 10px" }}>|</span>
          <strong>
            <a href="terminos-vendedor" style={{ color: "white" }}>
              Términos y condiciones del vendedor
            </a>
          </strong>
        </Col>
        <Col span={24}>
          <p>
            Av. Juan Rulfo 1851 – 69 Fracc. Sonterra, C.P 76235, Querétaro,
            México.
          </p>
        </Col>
        <Col span={24}>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>CONTACTO</p>
          <p>
            ayuda@blizu.com.mx
            <br />
            +52 1 442 345 9936
          </p>
        </Col>
        <Col span={24}>
          <p style={{ marginTop: "20px" }}>
            Copyright © 2024 la presente plataforma es operada por la marca
            comercial "Blizu".
          </p>
        </Col>
      </Row>
    </Footer>
  );
};

export default CustomFooter;
