import React, { useState, useEffect } from "react";
import { Layout, Typography, Row, Col, Card, Button, Spin } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { Outlet, useParams } from "react-router-dom";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import MenuListAdmin from "../../components/MenuListAdmin/MenuListAdmin";
import "./DashboardAdmin.scss";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

const { Sider, Content } = Layout;
const { Title } = Typography;

const DashboardAdmin = () => {
  const { id } = useParams();
  const db = getFirestore();

  const [vendedoresPendientes, setVendedoresPendientes] = useState(0);
  const [pagoAVendedores, setPagoAVendedores] = useState(0);
  const [loading, setLoading] = useState(true);
  const [datosPagoPendientes, setDatosPagoPendientes] = useState(0);
  const [chatsPendientes, setChatsPendientes] = useState(0);
  const [preguntasPendientes, setPreguntasPendientes] = useState(0);
  useEffect(() => {
    const unsubscribe = fetchData();
    return () => unsubscribe();
  }, []);

  const fetchData = () => {
    try {

      const preguntasCollection = collection(db, "ApoyoNuevaCuenta");
      const qPreguntas = query(preguntasCollection, where("revisado", "==", false));

      const unsubscribePreguntas = onSnapshot(qPreguntas, (snapshot) => {
        setPreguntasPendientes(snapshot.size);
      });


      
      const vendedoresCollection = collection(db, "roles");
      const q = query(vendedoresCollection, where("role", "==", 1));

      const unsubscribeVendedores = onSnapshot(q, (snapshot) => {
        const vendedoresData = snapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));

        const vendedoresFiltrados = vendedoresData.filter(
          (vendedor) => vendedor.aprobado === undefined
        );

        setVendedoresPendientes(vendedoresFiltrados.length);
      });

      const datosPagoCollection = collection(db, "datosPagoVendedor");
      const qDatosPago = query(datosPagoCollection, where("estado", "==", "pendiente"));

      const unsubscribeDatosPago = onSnapshot(qDatosPago, (snapshot) => {
        setDatosPagoPendientes(snapshot.size);

      });

      return () => {
        unsubscribeVendedores();
        unsubscribeDatosPago();
        unsubscribePreguntas();
      };
    } catch (error) {
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    const fetchProductos = async () => {
      setLoading(true);
      try {
        const productosQuery = query(
          collection(db, "productosPedidos"),
          where("tipo", "==", "producto"),
          where("estatus", "==", "Entrega realizada"),
          where("pagadoVendedor", "==", false)
        );

        const serviciosQuery = query(
          collection(db, "productosPedidos"),
          where("tipo", "==", "servicio"),
          where("estatus", "==", "Entrega realizada"),
          where("tipoServicio", "not-in", ["renta"]),
          where("pagadoVendedor", "==", false)
        );

        const rentasQuery = query(
          collection(db, "productosPedidos"),
          where("tipo", "==", "servicio"),
          where("tipoServicio", "==", "renta"),
          where("estatus", "==", "Producto de renta recuperado"),
          where("pagadoVendedor", "==", false)
        );

        
        const [productosSnapshot, serviciosSnapshot, rentasSnapshot] =
          await Promise.all([
            getDocs(productosQuery),
            getDocs(serviciosQuery),
            getDocs(rentasQuery),
          ]);

        const productosData = productosSnapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
          entregaProgramada: doc.data().entregaProgramada.toDate(),
        }));

        const serviciosData = serviciosSnapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
          entregaProgramada: doc.data().entregaProgramada.toDate(),
        }));

        const rentasData = rentasSnapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
          entregaProgramada: doc.data().entregaProgramada.toDate(),
        }));

        const combinedData = [
          ...productosData,
          ...serviciosData,
          ...rentasData,
        ];

        combinedData.sort((a, b) => a.entregaProgramada - b.entregaProgramada);

        setPagoAVendedores(combinedData.length);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching productosPedidos:", error);
        setLoading(false);
      }
    };

    fetchProductos();
  }, [db]);


  useEffect(() => {
    const fetchChatsPendientes = async () => {
      try {
        // Obtener todos los subpedidos
        const subpedidosCollection = collection(db, "subpedidos");
        const subpedidosSnapshot = await getDocs(subpedidosCollection);

        const allMensajesPendientes = [];

        // Iterar sobre cada subpedido
        for (const subpedido of subpedidosSnapshot.docs) {
          const messagesCollection = collection(
            db,
            "subpedidos",
            subpedido.id,
            "messages"
          );
          const messagesQuery = query(
            messagesCollection,
            where("status", "==", "pending"),      // Solo mensajes aprobados    
          );

          const messagesSnapshot = await getDocs(messagesQuery);
          const mensajes = messagesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(), // Incluye todos los datos del mensaje
          }));

          allMensajesPendientes.push(...mensajes);
        }

       // console.log(allMensajesPendientes); // Ver los mensajes pendientes en la consola

        setChatsPendientes(allMensajesPendientes.length); // Contar el número total de mensajes pendientes
      } catch (error) {
        console.error("Error fetching chats pendientes:", error);
      }
    };
  
    fetchChatsPendientes();
  }, [db]);
  
  return (
    <div>
      <div className="control-tamano">
        <Row>
          <Col span={4}>
            <Sider
              theme={"light"}
              className="sidebar"
              width={270}
              breakpoint="xl"
              collapsedWidth="80"
              style={{ height: "100vh",overflowY: 'auto' }} // Agregado overflowY para permitir scroll
            >
              <MenuListAdmin
                vendedoresPendientes={vendedoresPendientes}
                pagoAVendedores={pagoAVendedores}
                datosPendientes={datosPagoPendientes}
                mensajesPendientes={chatsPendientes}
                preguntasPendientes={preguntasPendientes}
              />
            </Sider>
          </Col>
          <Col span={20}>
            <Content style={{ minHeight: "100vh", marginTop: "20px", marginLeft: "35px" }}>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Outlet id={id} />
              )}
            </Content>
          </Col>
        </Row>
      </div>
      <div className="movil-versionadmin">
        <Card
          title="Panel no compatible con versión móvil"
          style={{ width: 350, textAlign: "center" }}
        >
          <img
            src={logo}
            className="logoNav"
            alt="blizu"
            style={{ width: "100px", marginBottom: "20px" }}
          />
          <p>
            Para poder visualizar el panel de administración es necesario ingresar
            desde un dispositivo con una pantalla más grande. ¡Gracias por tu
            comprensión!
          </p>
          <Link to="/">Volver a inicio</Link>
        </Card>
      </div>
    </div>
  );
};


export default DashboardAdmin;
