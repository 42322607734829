import React, { useState } from "react";
import { Form, Input, Button, Upload, Col, Row, Card, message, Checkbox  } from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  InboxOutlined,
  PhoneOutlined 
} from "@ant-design/icons";
import { auth, createUserWithEmailAndPassword } from "../../../firebaseConfig";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { createCustomer } from "../../../Api/Openpay/OpenPayApi";
import PhotoUploader from "../../../components/PhotoUploader/PhotoUploader";
import "./Registro.scss";
const { Dragger } = Upload;

const RegistroVendedor = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(null);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );

      const userId = userCredential.user.uid;
     // console.log(values);
      // Obtener una instancia de Firestore
      const db = getFirestore();

      const usersCollection = collection(db, "roles");
      const userDocRef = doc(usersCollection, userId);

      // Subir archivos a Firebase Storage
      const storage = getStorage();

      const ineRef = ref(storage, `vendedor/ine/${userId + "-" + values.ine[0].originFileObj.name}`);
      await uploadBytes(ineRef, values.ine[0].originFileObj);

    //  console.log(values.ineTrasera[0]);
      const ineTraseraRef = ref(storage, `vendedor/inetrasera/${userId + "-" + values.ineTrasera[0].originFileObj.name}`);
      await uploadBytes(ineTraseraRef, values.ineTrasera[0].originFileObj);


      const constanciaRef = ref(storage, `vendedor/constancia/${userId  + "-" + values.constancia[0].originFileObj.name}`);

      await uploadBytes(constanciaRef, values.constancia[0].originFileObj);
      var avatarLink = "";
      if (
        values.photo &&
        values.photo.length > 0 &&
        values.photo[0].originFileObj &&
        values.photo[0].originFileObj.name !== undefined &&
        imageUrl) {
        const avatarRef = ref(storage, `avatars/${userId}`);
        await uploadBytes(avatarRef,values.photo[0].originFileObj);
         avatarLink = await getDownloadURL(avatarRef);
 
      }
      // Obtener enlaces a los archivos subidos
      const ineLink = await getDownloadURL(ineRef);
      const ineTraseraLink = await getDownloadURL(ineTraseraRef);
      const constanciaLink = await getDownloadURL(constanciaRef);
      const userOpenpay = await createCustomer({
        name: values.username,
        email: values.email,
        phone_number: "",
        requires_account: false,
        // Agregar la ubicación al objeto de usuario
      });
      // Se sube la colección de datos del usuario
      await setDoc(userDocRef, {
        username: values.username,
        email: values.email,
        role: 1,
        ine: ineLink,
        ineTrasera: ineTraseraLink,
        constancia: constanciaLink,
        phone: values.phone,
        photo: avatarLink,
        activado: false,
        openpayId: userOpenpay.id,
        uid: userId,
      });
      
      message.success("Registro de vendedor exitoso espera la aprobación de 24 a 48 horas");
      navigate("/login");
      setLoading(false);
    } catch (error) {
      setLoading(false);
     
      console.error(error.message);
      if(error.message === "Firebase: Error (auth/email-already-in-use)."){
        toast.error("El correo ya tiene una cuenta existente en el sistema.");
      }else{
        toast.error("Error en el registro. Por favor, inténtalo de nuevo." + error.message);
      }
    }
  };


  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

   return (
    <div   className="registration-containerV" >
        <Toaster richColors position="top-center" />
      <Form
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        className="registration-formV"
        layout="vertical"
      >
        <h1 className="registration-title">Registro de vendedor</h1>
        <p style={{textAlign: 'center', marginBottom:'10px'}}>Nos da gusto que te quieras unir a la familia de vendedores de Blizu, para poder ser un vendedor se necesita esperar la validación y aprobación de la información aqui proporcionada, gracias</p>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {/* Primera columna */}

            <PhotoUploader  setImageUrl={setImageUrl} imageUrl={imageUrl}/>
            <Form.Item
              name="username"
              label="Nombre"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingresa tu nombre de usuario",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Nombre de Usuario"
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Correo electrónico"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingresa tu dirección de correo electrónico",
                },
                {
                  type: "email",
                  message: "Ingresa un correo electrónico válido",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Correo Electrónico"
              />
            </Form.Item>
            <Form.Item
          name="phone"
          label="Teléfono"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa tu número de teléfono",
            },
          ]}
        >
          <Input
            prefix={<PhoneOutlined className="site-form-item-icon" />}
            placeholder="Número de Teléfono"
          />
        </Form.Item>
            <Form.Item
              name="password"
              label="Contraseña"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingresa tu contraseña",
                },
                {
                  min: 8,
                  message: "La contraseña debe tener al menos 8 caracteres",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Contraseña"
              />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirmar contraseña"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Por favor, confirma tu contraseña",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Las contraseñas no coinciden");
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Confirmar Contraseña"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {/* Segunda columna */}
            <Form.Item
              name="ine"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra="Sube tu INE frontal"
              rules={[
                {
                  required: true,
                  message: "Por favor, sube una foto de la parte delantera de tu INE",
                },
              ]}
            >
              <Dragger
                name="file"
                customRequest={({ file, onSuccess }) => onSuccess()}
                listType="picture"
                accept=".pdf,.jpg,.png"
                maxCount={1}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Haz clic o arrastra el INE frontal a esta área
                </p>
                <p className="ant-upload-hint">Sube una imagen de tu INE.</p>
              </Dragger>
            </Form.Item>
            <Form.Item
              name="ineTrasera"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra="Sube tu INE trasera"
              rules={[
                {
                  required: true,
                  message: "Por favor, sube una foto de la parte trasera de tu INE",
                },
              ]}
            >
              <Dragger
                name="file"
                customRequest={({ file, onSuccess }) => onSuccess()}
                listType="picture"
                maxCount={1}
                accept=".pdf,.jpg,.png"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Haz clic o arrastra el INE trasera a esta área
                </p>
                <p className="ant-upload-hint">Sube una imagen de tu INE.</p>
              </Dragger>
            </Form.Item>

            <Form.Item
              name="constancia"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra="Sube tu constancia fiscal"
              rules={[
                {
                  required: true,
                  message: "Por favor, sube tu constancia fiscal",
                },
              ]}
            >
              <Dragger
                name="file"
                customRequest={({ file, onSuccess }) => onSuccess()}
                listType="picture"
                accept=".pdf,.jpg,.png"
                maxCount={1}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Haz clic o arrastra la constancia fiscal a esta área
                </p>
                <p className="ant-upload-hint">
                  Sube una imagen de tu constancia fiscal.
                </p>
              </Dragger>
            </Form.Item>

            
          </Col>
        </Row>
        <Form.Item
          name="agreeTerms"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject("Debes aceptar los términos y condiciones"),
            },
          ]}
        >
          <Checkbox>
            He leído y acepto los{" "}
            <a
              href="/terminos-vendedor"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", cursor: "pointer" }}
            >
              términos y condiciones del vendedor
            </a>{" "}
            además del{" "}
              <a
                href="/declaracion-privacidad"
                target="_blank"
                rel="noopener noreferrer"
              >
                aviso de privacidad y confidencialidad
              </a>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="registration-form-button"
          >
            Registrarse
          </Button>
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          ¿Ya tienes una cuenta? <a href="/login">Inicia sesión</a>
        </Form.Item>
      </Form>
 
    </div>
  );
};

export default RegistroVendedor;
