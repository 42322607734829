import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { UserProvider, useUser } from "./context/UserContext";
import "./App.scss";
//screens
import Registro from "./screens/Cliente/RegistroCliente/RegistroAdmin";
import RegistroVendedor from "./screens/Vendedor/RegistroVendedor/RegistroVendedor";
import Login from "./screens/Global/Login/Login";
import Productos from "./screens/Cliente/Home/Productos";
import Perfil from "./screens/Cliente/Perfil/Perfil";
import Producto from "./screens/Cliente/ProductoDetalles/Producto";
import Carrito from "./screens/Cliente/Cart/CartView";
import Pedidos from "./screens/Cliente/Pedidos/Pedidos";
import BuscadorProductos from "./screens/Cliente/Buscador/BuscadorProductos";
import Tipo from "./screens/Global/Tipo/Tipo";
import CrearTienda from "./screens/Vendedor/CreateTienda/CrearTienda";
import EditarTienda from "./screens/Vendedor/EditarTienda/EditarTienda";
import MisProductos from "./screens/Vendedor/MisProductos/MisProductos";
import CrearProducto from "./screens/Vendedor/CrearProducto/CrearProducto";
import EditarProducto from "./screens/Vendedor/EditarProducto/EditarProducto";
import Favoritos from "./screens/Cliente/Favoritos/Favoritos";
import TerminosCondiciones from "./screens/Global/InformacionPagina/TerminosCondiciones/TerminosCondiciones";
import DeclaracionPrivacidad from "./screens/Global/InformacionPagina/DeclaracionPrivacidad/DeclaracionPrivacidad";
import TerminosMarketplace from "./screens/Global/InformacionPagina/TerminosMarketplace/TerminosMarketplace";
import Cards from "./screens/Cliente/Cards/Cards";
import CrearServicio from "./screens/Vendedor/CrearServicio/CrearServicio";
import EstadisticasVendedor from "./screens/Vendedor/EstadisticasVendedor/EstadisticasVendedor";
import CategoriasAdmin from "./screens/Admin/Categorias/CategoriasAdmin";
import ComisionesAdmin from "./screens/Admin/Comisiones/ComisionesAdmin";
import VendedoresAdmin from "./screens/Admin/Vendedores/VendedoresAdmin";
import TiendasAdmin from "./screens/Admin/Tiendas/TiendasAdmin";
import CategoriasTipo from "./screens/Admin/Categorias/CategoriaTipo";
import UsuariosAdmin from "./screens/Admin/Usuario/UsuariosAdmin";
import EditarPerfil from "./screens/Cliente/EditarPerfil/EditarPerfil";
import DireccionesCliente from "./screens/Cliente/Direcciones/DireccionesCliente";
import MisServicios from "./screens/Vendedor/MisServicios/MisServicios";
import EditarServicio from "./screens/Vendedor/EditarServicio/EditarServicio";
import AdminsAdmin from "./screens/Admin/Admins/AdminsAdmin";
import RegistroAdmin from "./screens/Admin/RegistroAdmin/Registro";
import CarruselImagenes from "./screens/Admin/CarruselAdmin/CarruselImagenes";
//layouts
import DashboardVendedor from "./layouts/DashboardVendedor/DashboardVendedor";
import ClienteView from "./layouts/Cliente/ClienteView";
import DashboardAdmin from "./layouts/DashboardAdmin/DashboardAdmin";
import ForgotPassword from "./screens/Global/OlvideContra/ForgotPassword";
import Servicio from "./screens/Cliente/ServicioDetalles/Servicio";
import Checkout from "./screens/Cliente/Checkout/Checkout";
import Chat from "./screens/Cliente/Chat/Chat";
import { ConfigProvider } from "antd";
import locale from "antd/es/locale/es_ES";
import ClienteViewNavSinBuscador from "./layouts/Cliente/ClienteViewNavSinBuscador";
import DetallesPedidoCliente from "./screens/Cliente/PedidoDetalles/DetallesPedidoCliente";
import EstadisticasAdmin from "./screens/Admin/Dashboard/EstadisticasAdmin";
import PedidosAdmin from "./screens/Admin/Pedidos/PedidosAdmin";
import UsuariosVendedorAdmin from "./screens/Admin/UsuarioVendedor/UsuariosVendedorAdmin";
import SubpedidosVendedor from "./screens/Vendedor/SubpedidosVendedor/SubpedidosVendedor";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import DetalleSubpedidosVendedor from "./screens/Vendedor/DetalleSubpedidosVendedor/DetalleSubpedidosVendedor";
import PagoAVendedores from "./screens/Admin/PagoAVendedores/PagoAVendedores";
import ReseñasVendedor from "./screens/Cliente/VendedorReseñas/ReseñasVendedor";
import DatosDePagoAVendedor from "./screens/Vendedor/DatosPago/DatosDePagoAVendedor";
import AdminAprobarDatosPago from "./screens/Admin/AprobarDatosPago/AdminAprobarDatosPago";
import VendedorChat from "./screens/Vendedor/Chat/VendedorChat";
import AdminChatApproval from "./screens/Admin/AdminChat/AdminChatApproval";
import PendingMessagesView from "./screens/Admin/MensajesPendientes/PendingMessagesView";
import PreguntasAdmin from "./screens/Admin/PreguntasAdmin/PreguntasAdmin";

const PrivateRoute = ({ element, requiredRole }) => {
  const { userData, userDataAvailable } = useUser();
  const navigate = useNavigate();
  const db = getFirestore();
  useEffect(() => {
    const checkStoreExistence = async (userId) => {
      try {
        const storeDocRef = doc(db, `tiendas/${userId}`);
        const storeDoc = await getDoc(storeDocRef);
        return storeDoc.exists();
      } catch (error) {
        console.error("Error checking store existence:", error);
        return false;
      }
    };

    const validateAccess = async () => {
      if (userDataAvailable !== null) {
        if (userDataAvailable) {
          if (userData.role < requiredRole || userData.role === undefined) {
            navigate("/");
          } else if (userData.role === 2 && requiredRole === 1) {
            navigate("/");
          } else if (userData.role === 1 && requiredRole === 1) {
            const storeExists = await checkStoreExistence(userData.uid);
            if (!storeExists) {
              navigate("/creartienda");
            }
          }
        } else {
          navigate("/");
        }
      }
    };

    validateAccess();
  }, [userData, userDataAvailable, requiredRole, navigate, db]);

  return element;
};

export default function App() {
  return (
    <Router>
      <ConfigProvider locale={locale}>
        <UserProvider>
          <Routes basename="/">
            <Route path="/" element={<ClienteView />}>
              <Route path="" element={<Productos />} />
              <Route path="/producto/:id" element={<Producto />} />
              <Route path="/servicio/:id" element={<Servicio />} />
              <Route path="/buscador" element={<BuscadorProductos />} />
              <Route
                path="/reseñas/:vendedorUid"
                element={<ReseñasVendedor />}
              />
            </Route>

            <Route path="/" element={<ClienteViewNavSinBuscador />}>
              <Route
                path="/terminos-condiciones"
                element={<TerminosCondiciones />}
              />
              <Route
                path="/declaracion-privacidad"
                element={<DeclaracionPrivacidad />}
              />
              <Route
                path="/terminos-vendedor"
                element={<TerminosMarketplace />}
              />
            </Route>
            {/*Navegación cliente*/}
            <Route
              path="/"
              element={
                <PrivateRoute element={<ClienteView />} requiredRole={0} />
              }
            >
              <Route path="/perfil" element={<Perfil />} />
              <Route path="/perfil/editar" element={<EditarPerfil />} />
              <Route
                path="/perfil/direcciones"
                element={<DireccionesCliente />}
              />
              <Route path="/mistarjetas" element={<Cards />} />
              <Route path="/carrito" element={<Carrito />} />
              <Route path="/pedidos" element={<Pedidos />} />
              <Route path="/pedido/:id" element={<DetallesPedidoCliente />} />
              <Route path="/favoritos" element={<Favoritos />} />
              <Route path="/chat/:subpedidoId" element={<Chat />} />
              <Route
                path="/vendedor/chat/:subpedidoId"
                element={<VendedorChat />}
              />
            </Route>

            <Route
              path="/"
              element={
                <PrivateRoute
                  element={<ClienteViewNavSinBuscador />}
                  requiredRole={0}
                />
              }
            >
              <Route path="/checkout" element={<Checkout />} />
            </Route>
            {/*Navegación vista vendedor */}
            <Route
              path="/creartienda"
              element={
                <PrivateRoute element={<CrearTienda />} requiredRole={1} />
              }
            />

            <Route
              path="/dashboard"
              element={
                <PrivateRoute
                  element={<DashboardVendedor />}
                  requiredRole={1}
                />
              }
            >
              <Route
                path="/dashboard/tienda/editar"
                element={<EditarTienda />}
              />
              <Route
                path="/dashboard/misproductos"
                element={<MisProductos />}
              />
              <Route
                path="/dashboard/editarproducto/:id"
                element={<EditarProducto />}
              />
              <Route
                path="/dashboard/crearproducto"
                element={<CrearProducto />}
              />
              <Route
                path="/dashboard/misservicios"
                element={<MisServicios />}
              />
              <Route
                path="/dashboard/crearservicio"
                element={<CrearServicio />}
              />
              <Route
                path="/dashboard/mispedidos"
                element={<SubpedidosVendedor />}
              />
              <Route
                path="/dashboard/mispedidos/subpedidos/:id"
                element={<DetalleSubpedidosVendedor />}
              />
              <Route
                path="/dashboard/editarservicio/:id"
                element={<EditarServicio />}
              />
              <Route
                path="/dashboard/datosdepago"
                element={<DatosDePagoAVendedor />}
              />
              <Route path="/dashboard/" element={<EstadisticasVendedor />} />
            </Route>
            <Route
              path="/admin"
              element={
                <PrivateRoute element={<DashboardAdmin />} requiredRole={2} />
              }
            >
              {/* <Route path="/admin/" element={<EstadisticasAdmin/>} /> */}
              <Route path="/admin/carrusel" element={<CarruselImagenes />} />
              <Route path="/admin/categorias" element={<CategoriasAdmin />} />
              <Route
                path="/admin/categorias/productos"
                element={<CategoriasTipo tipo={"producto"} />}
              />
              <Route
                path="/admin/categorias/servicios"
                element={<CategoriasTipo tipo={"servicio"} />}
              />
              <Route path="/admin/comisiones" element={<ComisionesAdmin />} />
              <Route
                path="/admin/vendedores/pendientes"
                element={<VendedoresAdmin />}
              />
              <Route
                path="/admin/vendedores"
                element={<UsuariosVendedorAdmin activado={true} />}
              />
              <Route
                path="/admin/pagosavendedores"
                element={<PagoAVendedores />}
              />
              <Route
                path="/admin/aprobardatospago"
                element={<AdminAprobarDatosPago />}
              />
              <Route
                path="/admin/vendedores/blacklist"
                element={<UsuariosVendedorAdmin activado={false} />}
              />
              <Route
                path="/admin/usuarios"
                element={<UsuariosAdmin activado={true} />}
              />
              <Route
                path="/admin/usuarios/blacklist"
                element={<UsuariosAdmin activado={false} />}
              />
              <Route
                path="/admin/admins"
                element={<AdminsAdmin activado={true} />}
              />
              <Route
                path="/admin/admins/blacklist"
                element={<AdminsAdmin activado={false} />}
              />
              <Route path="/admin/admins/agregar" element={<RegistroAdmin />} />
              <Route path="/admin/" element={<EstadisticasAdmin />} />
              <Route path="/admin/pedidos" element={<PedidosAdmin />} />
              <Route path="/admin/preguntas" element={<PreguntasAdmin  revisado = {false} />} />
              <Route path="/admin/preguntas/respondidas" element={<PreguntasAdmin  revisado ={true} />} />
              <Route
                path="/admin/tiendas"
                element={<TiendasAdmin activado={true} />}
              />
              <Route
                path="/admin/tiendas/blacklist"
                element={<TiendasAdmin activado={false} />}
              />
                   <Route path="/admin/revicionchats" element={<PendingMessagesView />} />
               <Route path="/admin/chat/:subpedidoId" element={<AdminChatApproval />} />
            </Route>

            <Route path="/login" element={<Login />} />
            <Route path="/olvide" element={<ForgotPassword />} />
            <Route path="/registrarse/comprador" element={<Registro />} />
            <Route
              path="/registrarse/vendedor"
              element={<RegistroVendedor />}
            />
            <Route path="/tipo" element={<Tipo />} />
          </Routes>
        </UserProvider>
      </ConfigProvider>
    </Router>
  );
}
