import React, { useState, useEffect } from "react";
import { Card, Avatar, Button, Flex, Row, Col, Badge } from "antd";
import {
  EditOutlined,
  CreditCardOutlined,
  EnvironmentOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { useUser } from "../../../context/UserContext";
import HoverableCard from "../../../components/HoverableCard/HoverableCard";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { fetchOrdersAndPendingMessages } from "../../../utils/ordenes/ordenesUtils";

const { Meta } = Card;

const Perfil = () => {
  const { userData } = useUser();
  const [userInfo, setUserInfo] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalPendingMessages, setTotalPendingMessages] = useState(0); // Estado para contar mensajes pendientes

  useEffect(() => {
    const fetchOrdersAndMessages = async () => {
      if (userData) {
        setUserInfo(userData);
        try {
          const { ordersData, totalMessages } = await fetchOrdersAndPendingMessages(userData.uid);
          setOrders(ordersData);
          setTotalPendingMessages(totalMessages); // Actualiza el estado con el conteo total de mensajes
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      }
    };

    fetchOrdersAndMessages();
  }, [userData]);

  const getTotalPendingMessages = async (ordersData) => {
    let totalMessages = 0;

    for (const order of ordersData) {
      const subpedidosCollection = collection(db, "subpedidos");
      const subpedidosQuery = query(
        subpedidosCollection,
        where("pedidoId", "==", order.id)
      );
      const subpedidosSnapshot = await getDocs(subpedidosQuery);

      for (const subpedidoDoc of subpedidosSnapshot.docs) {
        const messagesCollection = collection(
          db,
          "subpedidos",
          subpedidoDoc.id,
          "messages"
        );
        const messagesQuery = query(
          messagesCollection,
          where("status", "==", "approved"),
          where("read", "==", false)
        );
        const messagesSnapshot = await getDocs(messagesQuery);
        totalMessages += messagesSnapshot.size;
      }
    }

    setTotalPendingMessages(totalMessages); // Actualiza el estado con el conteo total de mensajes
  };

  return (
    <Flex vertical>
      <Card style={{ marginBottom: "16px", textAlign: "center" }}>
        <Avatar
          size={100}
          src={userInfo.photo}
          style={{
            marginBottom: "8px",
            border: "2px solid #1890ff",
            borderRadius: "50%",
          }}
        />
        <Meta title={userInfo.username} description={userInfo.email} />
        <Button
          style={{ marginTop: "8px" }}
          icon={<EditOutlined />}
          href="/perfil/editar" // Agrega esta línea para establecer el enlace
        >
          Editar mi perfil
        </Button>
      </Card>

      <Card style={{ margin: "auto" }} bodyStyle={{ padding: "24px" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24}>
            <HoverableCard
              title="Mis pedidos"
              description="Mis pedidos realizados."
              icon={
                <ShoppingOutlined
                  style={{ fontSize: "56px", marginRight: "16px" }}
                />
              }
              pendientes={totalPendingMessages}
              destinationUrl="/pedidos"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24}>
            <HoverableCard
              title="Tarjetas"
              description="Tarjetas guardadas en tu cuenta."
              icon={
                <CreditCardOutlined
                  style={{ fontSize: "56px", marginRight: "16px" }}
                />
              }
              destinationUrl="/mistarjetas"
            />
           
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24}>
            <HoverableCard
              title="Direcciones"
              description="Direcciones guardadas en tu cuenta."
              icon={
                <EnvironmentOutlined
                  style={{ fontSize: "56px", marginRight: "16px" }}
                />
              }
              destinationUrl="/perfil/direcciones"
            />
          </Col>
        </Row>
      </Card>
    </Flex>
  );
};

export default Perfil;
