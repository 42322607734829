// DeclaracionPrivacidad.jsx

import React from "react";
import { Typography } from "antd";
import "./DeclaracionPrivacidad.css";

const { Title, Paragraph } = Typography;

const DeclaracionPrivacidad = () => {
  return (
    <div className="declaracion-container">
      <Title level={2}>
        Declaración de privacidad y confidencialidad de la información de Blizu
      </Title>
      <Paragraph strong>
        Versión actualizada al 21 de Diciembre de 2023
      </Paragraph>
      <Paragraph>
        En Blizu entendemos a la protección de los datos personales como una
        oportunidad para generar valor para nuestros usuarios. Haciendo un uso
        responsable de la información personal, no sólo protegemos la privacidad
        de quienes nos confiaron sus datos, sino que les permitimos operar con
        seguridad y confianza en nuestro ecosistema. Por eso, tu privacidad es
        muy importante para nosotros y nos esforzamos para protegerla.
      </Paragraph>
      <Paragraph>
        Blizu, con el fin de poder brindarte sus servicios, trata, recolecta y
        en algunos casos da a conocer información sobre las personas que, como
        tú, son usuarios y visitantes de los sitios web y/o aplicaciones
        móviles, conforme se detalla aquí (las "Plataformas" y los "Servicios",
        respectivamente). Esta Declaración de Privacidad y Confidencialidad de
        la Información de Blizu (la "Declaración de Privacidad") describe la
        información que Blizu recolecta y trata sobre ti y lo que puede hacer
        con ella.
      </Paragraph>
      <Paragraph>
        Esta Declaración de Privacidad forma parte de los Términos y Condiciones
        Generales de Blizu. Prestar tu consentimiento voluntario, expreso e
        informado a esta Declaración de Privacidad es un requisito esencial para
        poder contratar y/o tener cualquier tipo de relación con Blizu,
        dependiendo de la legislación aplicable en cada país.
      </Paragraph>
      <Title level={3}>
        1. ¿Cómo se integra Blizu y cómo aplica esta Declaración de Privacidad?
      </Title>
      <Paragraph>
        El objetivo de Blizu es democratizar el comercio, el dinero, los pagos,
        los envíos y el crédito. Para ello ha generado un ecosistema de
        servicios integrados a través de su plataforma que conforma un todo y
        son necesarias e indispensables para brindarte los servicios de Blizu.
        Por esta razón, es importante que tengas en cuenta que cuando te
        registras en la plataforma, estás generando un usuario que te permitirá
        operar en dicha plataforma. Para ello es necesario que tus datos sean
        compartidos entre todos los servicios que conforman Blizu.
      </Paragraph>
      <Paragraph>
        En la sección 5. “¿Cómo compartimos la información personal?” podrás
        encontrar más información respecto a esto último. Esta Declaración de
        Privacidad aplica a todos los servicios y operaciones de tratamiento de
        datos de Blizu, ya sea que se trate de Blizu.
      </Paragraph>
      <Paragraph>
        Creemos importante destacar que los servicios de Blizu y cada una de sus
        plataformas están destinados a usuarios ubicados en aquellos países en
        los que Blizu tenga presencia u operación; por ejemplo, mediante un
        Sitio Web específico para un país determinado.
      </Paragraph>
      <Title level={3}>
        2. ¿Quién es el responsable del tratamiento de la información personal?
      </Title>
      <Paragraph>
        Blizu es el responsable del tratamiento de los datos de los usuarios y
        de los visitantes de su plataforma. El responsable del tratamiento es
        quien decide sobre el tratamiento de los datos personales. Para ello
        determina los fines o usos para los que se utilizará la información
        personal y los medios que serán utilizados para ese tratamiento
        (consulta la Sección 4. “¿Para qué se utilizará la información
        personal?”).
      </Paragraph>
      <Title level={3}>3. ¿Qué información recolectamos y tratamos?</Title>
      <Paragraph>
        Blizu recolecta tu información personal para que puedas disfrutar de
        nuestros servicios, y poder mejorarlos de manera continua.
      </Paragraph>
      <Paragraph>
        En algunos casos, la información la facilitas tú mismo, al registrarte o
        al proveer información cuando utilizas alguno de nuestros servicios. En
        otros, los recolectamos automáticamente, como cuando navegas por
        nuestras páginas y utilizas nuestros servicios. También podemos
        recolectar información acerca de ti de otras fuentes confiables.
      </Paragraph>
      <Paragraph>
        No tienes la obligación de proporcionarnos la información personal que
        se detalla a continuación, sin embargo, este es un requisito esencial
        para poder contratar y/o tener cualquier tipo de relación con Blizu y,
        si no proporcionas esta información, no podremos brindarte nuestros
        servicios o nuestra capacidad para hacerlo puede verse
        significativamente obstaculizada. La inexactitud o falsedad de los datos
        personales que proporciones podría causar la suspensión de los
        servicios. Asimismo, Blizu podrá suspender o inhabilitar definitivamente
        a aquellos usuarios que violen esta Declaración de Privacidad.
      </Paragraph>
      <Paragraph>
        Estos son los tipos de datos que podríamos recolectar:
      </Paragraph>
      <Title level={4}>
        • Información que nos proporcionas directamente al registrarte o
        utilizar nuestros servicios:
      </Title>
      <Paragraph>
        o Apodo o seudónimo para operar en las plataformas de Blizu. <br />
        o Nombre, imagen personal (foto personal o foto del documento).
        <br />
        o Número de documento o identificación válida.
        <br />
        o Información de contacto (como número de teléfono, domicilio, dirección
        de correo electrónico).
        <br />
        o Datos de cuenta bancaria.
        <br />
        o Información y medios de pago.
        <br />
      </Paragraph>
      <Title level={4}>
        • Información que recopilamos de manera automática, ya sea que te
        encuentres registrado o no:
      </Title>
      <Paragraph>
        o Información de los dispositivos o computadoras desde los que accedes a
        la plataforma de Blizu y otros datos capturados automáticamente (como el
        tipo o versión del navegador o del sistema operativo, configuraciones,
        datos de conexión, información sobre algunas de las aplicaciones
        descargadas y parámetros). <br />
        o Dirección IP de internet que utilizas al conectarte a nuestros
        servicios o al navegar nuestros sitios web. <br />
        o Información transaccional y movimientos dentro de las plataformas de
        Blizu (compras, pagos, devoluciones, preguntas, reclamos, facturación,
        datos fiscales, clave o código de identificación de cuenta digital (CVU,
        CLABE, etc.), cuentas bancarias, mensajería interna). <br />
        o Cierta información sobre la actividad de los usuarios y visitantes
        dentro de nuestro sitio web y las apps. Como por ejemplo, la URL de la
        que provienen o a qué URL acceden seguidamente (estén o no en nuestro
        sitio web). También las páginas visitadas, las interacciones con dichas
        páginas, las búsquedas realizadas, las publicaciones, compras o ventas,
        calificaciones y réplicas ingresadas, reclamos realizados y recibidos,
        mensajes en los foros, entre otra información podrá ser almacenada y
        retenida. <br />
        o Información sobre tu ubicación (geolocalización), que puede ser
        utilizada para ofrecerte descuentos, retiro de productos, localizar
        comercios con QR habilitado para el pago, entre otros. <br />
        o Vinculaciones entre cuentas y usuarios, con motivo de acciones de
        prevención del fraude. <br />
        o Datos para gestión de reclamos y juicios (información para la
        elaboración de documentos, antecedentes y estrategias). <br />
        o Si no te registraste ni accediste a través de tu Cuenta Personal,
        Blizu solamente recolectará y almacenará, además de la información
        indicada en este punto, tus números de teléfono celulares, tu dirección
        de e-mail y correos electrónicos que nos envíes. <br />
      </Paragraph>
      <Title level={4}>• Información que recopilamos de otras fuentes:</Title>
      <Paragraph>
        o Información recopilada para finalidades de prevención de fraude y
        cumplimiento de regímenes informativos (listas PEPs, OFAC, etc.).
        <br />
        o Datos que se utilizan para la validación de identidad, completar o
        corregir información, obtenidos de fuentes seguras y confiables, tales
        como organismos públicos, proveedores de servicios o aliados comerciales
        con los que trabajamos.
        <br />
      </Paragraph>
      <Title level={3}>4. ¿Qué hacemos con la información personal?</Title>
      <Paragraph>
        La recolección y tratamiento de tu información personal nos permite
        prestarte un excelente servicio para que puedas realizar operaciones de
        forma rápida y segura y ofrecerte funcionalidades que se adaptan mejor a
        tus necesidades. Salvo en aquellos casos en los que la normativa
        aplicable lo prohíba, Blizu podrá utilizar tu información personal para
        las siguientes finalidades.
      </Paragraph>
      <Title level={3}>
        Brindar los productos, servicios y/o beneficios que ofrece la plataforma
        de Blizu.
      </Title>
      <Paragraph>
        o Identificarte y contactarte.
        <br />
        o Registrarte en nuestros sistemas.
        <br />
        o Verificar tu identidad en cumplimiento de exigencias legales.
        <br />
        o Validar, actualizar y corregir tu información.
        <br />
        o Brindarte los productos, servicios y/o beneficios que solicitas o
        contratas con nosotros.
        <br />
        o Facilitarte entrar en contacto directo con el vendedor o comprador
        para efectos de la transacción que quieres realizar.
        <br />
        o Elaborar y mantener un registro de las operaciones que realices, así
        como informarte acerca de las mismas y darle seguimiento
        correspondiente.
        <br />
        o Poner a tu disposición nuestra plataforma de comercio electrónico.
        <br />
        o Gestionar los servicios y productos de Blizu, incluyendo el servicio
        de procesamiento de pagos y la cuenta digital.
        <br />
        o Atender tus comentarios, quejas y sugerencias, así como brindarte
        soporte.
        <br />
        o Cobranza judicial y/o extrajudicial.
        <br />
        o Facilitar el envío de productos anunciados en sitios web, aplicaciones
        y demás plataformas de comercio electrónico operadas por Blizu, o las
        que utilicen sus tecnologías.
        <br />
        o Ofrecerte servicios y funcionalidades que se adecuen mejor a tus
        necesidades, y personalizar nuestros servicios para hacer que tus
        experiencias con Blizu sean lo más cómodas posible.
        <br />
        o Posibilitarte la participación en concursos, subastas o sorteos, en
        caso de realizarlos y de que te sean aplicables, así como notificarte si
        resultaras ganador, lo anterior siempre en cumplimiento a la normativa
        aplicable a sorteos y concursos.
        <br />
        o Contribuir a la seguridad de las relaciones, comunicaciones y
        transacciones entre los usuarios de nuestra plataforma.
        <br />
        o Elaborar un sistema de reputación de usuarios, para beneficio de los
        consumidores.
        <br />
      </Paragraph>
      <Title level={3}>
        Mejorar nuestros servicios, desarrollar nuevos y ofrecerte una mejor
        experiencia con las plataformas de Blizu.
      </Title>
      <Paragraph>
        o Desarrollar estudios internos y estadísticos sobre tus intereses y
        comportamientos, para ofrecerte mejores servicios y productos. <br />
        o Elaborar perfiles mediante el análisis de diversas variables, como la
        conducta o las interacciones dentro de la plataforma, el análisis y
        predicción de la capacidad económica, preferencias, intereses, historial
        de transacciones, comportamiento y ubicación, entre otros, para mejorar
        nuestras iniciativas comerciales y promocionales, mostrar publicidad o
        promociones, banners de interés, noticias sobre las plataformas de
        Blizu, perfeccionar nuestra oferta de contenidos y artículos,
        personalizar dichos contenidos, presentación y servicios. <br />
        o Ofrecerte servicios y funcionalidades que se adecuen a tus necesidades
        para brindarte una mejor experiencia. <br />
        o Mejorar nuestras iniciativas comerciales y promocionales y analizar
        las páginas visitadas, las búsquedas realizadas por los usuarios, para
        mejorar nuestra oferta de contenidos y artículos, personalizar dichos
        contenidos, su presentación y servicios. <br />
        o Capacitar a consultoras o consultores independientes para mejorar su
        propuesta de servicios a los usuarios de Blizu. <br />
        o Brindarte información a través de diferentes canales (por correo
        electrónico, mensajes cortos de texto (SMS), mensajes push, llamada
        telefónica o cualquier otro medio) sobre mejoras o nuevas funciones o
        servicios de la plataforma. <br />
        o Buscar tu fidelización mediante un programa de beneficios. <br />
        o Permitir a terceros, mediante el uso de APIs públicas, construir
        aplicaciones que empleen contenidos de la plataforma e interactúen con
        ella para facilitar, mejorar y/o potenciar su uso y sus funcionalidades
        y de los servicios en general prestados por Blizu. <br />
      </Paragraph>
      <Title level={3}>
        Cumplir con obligaciones legales y requerimientos de autoridades
        competentes.
      </Title>
      <Paragraph>
        o Cumplir con la normativa que le sea aplicable a Blizu en general.
        <br />
        o Cumplimiento de normativa de Prevención de Lavado de Dinero y
        Financiamiento del Terrorismo (acciones de validación de identidad,
        verificación de usuarios (KYC), verificación de identidad contra de
        Personas Expuestas Políticamente, verificación de perfil e historial
        transaccional, en cumplimiento de la regulación aplicable en materia de
        prevención de lavado de dinero, verificación contra listas OFAC y
        otras), según sea aplicable en cada país.
        <br />
        o Cumplimiento de regímenes informativos en general, según sea aplicable
        en cada país.
        <br />
        o Cumplimiento de regímenes fiscales de recaudación, registración,
        información, auditoría y facturación, a cualquier nivel de gobierno (por
        ejemplo: nivel federal, estadual y municipal).
        <br />
        o Cumplimiento de requerimientos informativos de autoridades
        administrativas o judiciales competentes.
        <br />
        o Suministrar información de usuarios a entidades gubernamentales con
        acuerdos de colaboración para el cumplimiento de sus competencias.
        <br />
      </Paragraph>
      <Title level={3}>
        Detectar y prevenir fraudes, abusos y delitos relacionados para proteger
        la seguridad de nuestros usuarios y la sustentabilidad de las
        plataformas.
      </Title>
      <Paragraph>
        o Hacer que las plataformas crezcan de una manera sustentable y segura
        mediante herramientas y acciones de prevención del fraude y delitos
        relacionados. <br />
        o Entrenar el modelo de algoritmo de detección y prevención automatizada
        del fraude. <br />
      </Paragraph>
      <Title level={3}>
        Proteger derechos de usuarios, de terceros o propios de Blizu.
      </Title>
      <Paragraph>
        o Defender los derechos, activos tangibles e intangibles de Blizu.{" "}
        <br />
        o Hacer valer los derechos de Blizu ante incumplimientos de sus Términos
        y Condiciones. <br />
        o Permitir que otros usuarios o terceros puedan hacer valer sus
        derechos. <br />
      </Paragraph>
      <Title level={3}>
        Colaborar con los titulares de propiedad intelectual en el ejercicio de
        sus derechos
      </Title>
      <Paragraph>
        o Colaborar con la protección de los derechos de propiedad intelectual o
        industrial, propios o de terceros, investigaciones de violación de
        marcas y patentes, así como falsificaciones.
        <br />
        o Colaborar con la autoridad competente, brindando la información
        personal de los vendedores denunciados, para que los compradores puedan
        ejercer sus derechos fuera de la plataforma. <br />
        o Facilitar la resolución de disputas entre vendedores y compradores que
        los denunciaron. <br />
        o Colaborar con las entidades que intervengan en la resolución de
        disputas.
        <br />
        o Colaborar con entidades privadas que representen intereses colectivos
        con acuerdo de colaboración, para facilitar la resolución de las
        disputas.
        <br />
      </Paragraph>
      <Title level={3}>
        Efectuar anuncios y contactos publicitarios y promocionales.
      </Title>
      <Paragraph>
        o Contactarte a través de diferentes canales (por correo electrónico,
        mensajes cortos de texto (SMS), mensajes push, llamadas telefónicas o
        cualquier otro medio) para finalidades publicitarias y/o promocionales
        de productos y servicios de Blizu y/o de terceros. <br />
        o Efectuar todo tipo de actividades de mercadotecnia, publicidad,
        prospección comercial y/o estudios de mercado. <br />
      </Paragraph>
      <Paragraph>
        Algunas de estas finalidades descritas podrían no ser aplicables en
        cuanto: a) no utilices el servicio asociado a dichas finalidades, o b)
        los servicios vinculados a esas finalidades no estén ofrecidos en tu
        país de residencia.
      </Paragraph>
      <Paragraph>
        La utilización de tus datos para cualquier otra finalidad que no sea
        compatible con las detalladas, te será comunicada antes de que
        procedamos a su tratamiento.
      </Paragraph>
      <Paragraph>
        Blizu mantendrá tu información personal durante el plazo que sea
        necesario para cumplir con los fines para los cuales fueron
        recolectados.
      </Paragraph>
      <Title level={3}>5. ¿Cómo compartimos la información personal?</Title>
      <Paragraph>
        El resguardo de tu privacidad es muy importante para Blizu. Por ello, no
        vendemos ni comercializamos información que identifique a nuestros
        usuarios. Tampoco compartimos o transferimos de ningún otro modo tu
        información personal a terceros, salvo de la manera indicada a
        continuación:
      </Paragraph>
      <Paragraph>
        Blizu podrá ceder, transmitir y/o transferir tu información personal a:
      </Paragraph>
      <Paragraph>
        (I) "Proveedores de Servicios": los proveedores de servicios o empresas
        son terceros que contratamos para que actúen en nombre de Blizu para que
        presten un servicio siguiendo nuestras instrucciones y de conformidad a
        lo establecido en esta Declaración de Privacidad, para contribuir a
        mejorar o facilitar las operaciones a través de nuestra plataforma,
        como:
      </Paragraph>
      <Paragraph>
        • Empresas de transporte, logística, mensajería y paquetería, para
        hacerte llegar los productos que adquiriste. <br />
        • Medios de pago, intermediarios en la gestión de pagos o seguros, para
        obtener el pago por los servicios o productos contratados, así como
        brindarte protección respecto de los productos adquiridos. <br />
        • Proveedores de sistemas informáticos, proveedores de servicios en la
        nube, proveedores de bases de datos, y proveedores de servicios
        tecnológicos en general. <br />
        • Call centers o centros de atención al cliente. <br />
        • Empresas que manejen programas de fidelidad o lealtad, para otorgarte
        beneficios. <br />
        • Agencias de publicidad o marketing. <br />
        • Análisis de datos. <br />
        • Agencias de cobranzas. <br />
        • Entidades gubernamentales o empresas especializadas en riesgos, con la
        finalidad de verificar tu información. Estos Proveedores de Servicios
        sólo acceden a la información estrictamente necesaria para prestar los
        servicios acordados y no pueden usarlos para finalidades distintas a las
        que les encomiende Blizu. <br />
      </Paragraph>
      <Paragraph>
        (II) “Aliados comerciales”: empresas con actividades financieras o
        comerciales con las que Blizu tenga una relación de colaboración o
        alianza, con la finalidad de ofrecerte promociones, productos y
        servicios de dichas empresas o de marca compartida. En estos casos,
        Blizu vela siempre porque se cumplan estándares de confidencialidad y
        seguridad, mediante la firma de acuerdos o convenios cuyo objeto sea la
        privacidad de los datos personales de nuestros usuarios y el
        cumplimiento de la legislación aplicable.
        <br />
        (III) "Grupo Blizu": sociedades que pertenezcan a nuestro grupo
        corporativo, que operan bajo nuestros mismos procesos y políticas
        internas, ya sean sociedades controladas, controlantes o afiliadas con
        Blizu, para cumplir con nuestra normativa interna, prevenir fraudes,
        gestionar riesgos y facilitar la gestión de los servicios y productos de
        Blizu.
        <br />
        (IV) “Contraparte en la transacción”: otros usuarios de las plataformas
        de Blizu, en las condiciones previstas, con la finalidad de ponerlo en
        contacto con el vendedor o comprador para efectos de la transacción que
        desea realizar.
        <br />
        (V) “Autoridades públicas”: las autoridades administrativas y judiciales
        que en ejercicio de su competencia requieran información, aunque no
        exista una orden ni una citación ejecutiva o judicial al efecto, con la
        finalidades de: (a) colaborar en la investigación y denunciar fraudes,
        piratería, violaciones de propiedad intelectual o industrial o cualquier
        otro ilícito, así como cualquier actividad o circunstancia que pudiera
        generarle responsabilidad legal a Blizu y/o a sus usuarios; (b)
        salvaguardar de un interés público, la procuración o administración de
        justicia, el reconocimiento, ejercicio o defensa de un derecho en un
        proceso judicial o administrativo, y/o la resolución de controversias; y
        (c) dar cumplimiento a alguna ley, reglamento o disposición legal
        aplicable, o a algún mandato de autoridad competente debidamente fundado
        y motivado.
        <br />
        (VI) "Intervinientes en Disputas”: autoridades, amigables componedores,
        tribunales o entidades que intervengan en solución de disputas con la
        finalidad de resolver las controversias que se llegaran a suscitar entre
        usuarios o entre éstos y cualquiera de las empresas del grupo
        corporativo de Blizu.
        <br />
        (VII) “Usuarios y/o visitantes en general”: otros usuarios y/o
        visitantes de nuestra plataforma, con la finalidad de calidad del
        servicio al consumidor final, brindándole información para que tome una
        decisión de compra informada. Se publicará en la plataforma el número de
        ventas realizadas por el vendedor, así como reputación, atención
        brindada, opiniones de los compradores (cuyo contenido no es
        responsabilidad de Blizu) y puntualidad en las entregas de los
        productos.
        <br />
        Así mismo, Blizu podrá divulgar tu información personal
        discrecionalmente a otros usuarios de los Sitios Web y/o los Servicios,
        entidades o terceros cuando haya motivos suficientes para considerar que
        tu actividad sea sospechosa de intentar o cometer un delito o intentar
        perjudicar a otras personas.
        <br />
        Si Blizu decidiese compartir tu información personal con terceros
        distintos a los mencionados, solicitaremos tu consentimiento previo y
        expreso, siempre y cuando no exista una autorización u obligación legal
        que permita realizarlo sin ese consentimiento.
        <br />
        Así mismo, prestas tu consentimiento expreso e informado para que Blizu
        ceda, transmita o transfiera tu información personal a los destinatarios
        detallados en esta Declaración de Privacidad.
        <br />
        Finalmente, Blizu no será responsable por el uso indebido de tu
        información personal que haga cualquier tercero cuando sean estos
        terceros quienes directamente recolecten y/o traten tu información
        personal.
        <br />
      </Paragraph>
      <Title level={3}>6. Transferencias internacionales de datos</Title>
      <Paragraph>
        Los servicios que brinda Blizu requieren del soporte de una
        infraestructura tecnológica, como servidores y servicios en la nube, que
        pueden ser propios o provistos por terceros.
        <br />
        Parte de esa infraestructura puede estar establecida en un país
        diferente al tuyo.
        <br />
        También puede ocurrir que los destinatarios de los datos indicados
        anteriormente en la sección “¿Cómo compartimos la información personal?”
        se encuentren en un país distinto.
        <br />
        Puede que los países receptores de los datos que transferimos no
        ofrezcan niveles adecuados de protección de datos personales, conforme
        la normativa aplicable.
        <br />
        En estos casos, Blizu adopta medidas para resguardar tus datos, mediante
        cláusulas contractuales o normas corporativas vinculantes que imponen
        las mismas medidas de protección que las que se describen en esta
        Declaración de Privacidad.
        <br />
      </Paragraph>
      <Title level={3}>
        7. ¿Por cuánto tiempo vamos a almacenar la información personal?
      </Title>
      <Paragraph>
        Solo almacenaremos la información personal durante el tiempo necesario
        para cumplir con el propósito para el que se ha recopilado, para cumplir
        con requisitos reglamentarios o legales, o durante el periodo de
        prescripción legal de posibles responsabilidades legales o
        contractuales. <br />
        Una vez concluido el lapso, los datos serán eliminados o anonimizados de
        manera tal que no pueda ser individualizada ninguna persona, según lo
        permita la normativa de cada país.
        <br />
      </Paragraph>
      <Title level={3}>
        8. Decisiones automatizadas y elaboración de perfiles
      </Title>
      <Paragraph>
        En Blizu estamos comprometidos con brindarte más servicios y mejores,
        que simplifiquen tu vida. <br />
        Para esto recurrimos a herramientas que nos ayudan a ser más eficientes
        mediante técnicas conocidas como “inteligencia artificial”, “machine
        learning” o “Big Data” que utilizamos para diversos fines, como
        prevención del fraude, análisis y predicción de la capacidad económica,
        personalización de servicios y publicidad, mecanismos de ciberseguridad
        automatizados o validación de identidad mediante reconocimiento de
        imágenes. <br />
        Las decisiones automatizadas son aquellas que se toman en base al uso de
        algoritmos y programas informáticos, sin que intervenga ningún ser
        humano en el proceso de la toma de decisión. <br />
        Por su parte, la elaboración de perfiles es la evaluación de algunos
        aspectos personales, como tus intereses, preferencias, comportamientos o
        ubicación, que se realiza procesando de manera automatizada información
        personal con procedimientos estadísticos. Como consecuencia de este
        tratamiento, podríamos enviarte comunicaciones personalizadas, o
        mostrártelas dentro de nuestras plataformas, que creemos que pueden ser
        de tu interés. <br />
        Tienes derecho a solicitar que se revise una decisión basada en un
        tratamiento automatizado de datos <br />
      </Paragraph>
      <Title level={3}>
        ¿Cómo solicito la revisión de decisiones automatizadas como
        inhabilitación de cuenta, y otras acciones de Blizu?
      </Title>
      <Paragraph>
        En Blizu tomamos decisiones automotizadas que están basadas en
        mecanismos de inteligencia artificial, machine learning y big data, para
        actuar de forma segura y brindarte un mejor servicio. <br />
        Algunas de esas son: <br />
        • Inhabilitación de cuenta por sospecha de fraude o de seguridad <br />
        • Cálculo de Reputación como vendedor <br />
        • Utilización de datos para generar facturas <br />
      </Paragraph>
      <Title level={3}>
        9. Confidencialidad de la información personal y Responsabilidad de los
        Usuarios
      </Title>
      <Paragraph>
        Haremos todo lo que esté a nuestro alcance para proteger la privacidad
        de tu información personal. El usuario será responsable de todos los
        actos que tengan lugar mediante el uso de su apodo y clave. Si por
        cualquier razón creyeras que alguien puede conocer tu clave, deberás
        modificarla ingresando desde el menú de navegación en Mi cuenta¬ –
        Configuración – Datos personales.
      </Paragraph>
      <Title level={3}>10. Menores de Edad</Title>
      <Paragraph>
        Los Servicios de Blizu podrán estar disponibles para los menores de
        edad, mediante el previo consentimiento del representante legal. Si
        estás comprendido en esta categoría y no cuentas con el consentimiento
        de tu representante, no deberás suministrar tu información personal.{" "}
        <br />
        A través de tu representante legal podrás también ejercer tus derechos
        que se mencionan en la cláusula 13 de esta Declaración.
        <br />
      </Paragraph>
      <Title level={3}>
        11. El uso de la información personal por otros Usuarios
      </Title>
      <Paragraph>
        Blizu permite a los usuarios un acceso limitado a ciertos datos (como
        nombre, apodo, correo electrónico, otros datos de contacto e información
        para facturación y envíos) del resto de los usuarios para facilitar la
        interacción entre ellos. Bajo ninguna circunstancia otros usuarios
        podrán comunicar tu información personal a terceros sin tu
        consentimiento y/o el consentimiento de Blizu, según corresponda. <br />
        Cuando un usuario vendedor recibe información personal de sus
        compradores, se constituye en responsable por el tratamiento de esos
        datos y adquiere las obligaciones que ello implica. Por ello, no puede
        usar los datos para una finalidad distinta o incompatible a la
        transacción que originó la recolección. Por esta razón, sólo podrán
        utilizar la información personal de otros usuarios obtenida en el sitio
        para: (a) fines relacionados con transacciones en la plataforma de
        Blizu, (b) utilizar servicios ofrecidos en Blizu (por ejemplo: depósito,
        envío o transporte y reclamaciones sobre fraude), y (c) cualquier otra
        finalidad a la que el usuario correspondiente consienta expresamente una
        vez le haya sido comunicada previamente la información legalmente
        requerida o la normativa local habilite. <br />
        Blizu no acepta conductas consideradas "spamming". Queda absolutamente
        prohibido el envío indiscriminado de mensajes de cualquier naturaleza
        entre los usuarios de Blizu. <br />
        Blizu no controla, almacena ni tiene acceso a los mensajes y contenidos
        enviados por los usuarios a través de medios diferentes a los Sitios
        Web, por lo que no asume ninguna responsabilidad respecto a los daños
        que pudiera ocasionar su uso. Las negociaciones llevadas a cabo por los
        usuarios a través de dichos medios quedan bajo la propia responsabilidad
        de dichos usuarios. <br />
      </Paragraph>
      <Title level={3}>
        Blizu no es responsable del uso que cualquier usuario u otra persona
        pudieran hacer de la información publicada en las Plataformas.
      </Title>
      <Paragraph>
        Los usuarios aceptan que Blizu no será responsable de las pérdidas ni
        daños que se puedan generar como resultado de las negociaciones entre
        usuarios. En consecuencia, liberas a Blizu de todo tipo de
        responsabilidad en caso de que tengas un conflicto con uno o más
        usuarios.
      </Paragraph>
      <Title level={3}>12. Enlaces a otros sitios web</Title>
      <Paragraph>
        A través de las plataformas, Blizu podría incluir enlaces a sitios web
        de terceros, lo cual no indica que sean propiedad de, o sean operados
        por Blizu. Se aclara que el procesamiento de la información personal que
        hagan los terceros responsables de los sitios web antes mencionados no
        está alcanzado por esta Declaración de Privacidad. Así mismo, reconoces
        y aceptas que Blizu no tiene control sobre tales sitios web y no es ni
        será responsable por el contenido ni por los servicios prestados por
        tales sitios, ni por el modo en que éstos procesen tu información
        personal, por lo que ingresas a dichos sitios web bajo tu propia
        responsabilidad.
      </Paragraph>
      <Title level={3}>
        13. ¿Cómo puedes ejercer tus derechos para controlar tu información
        personal?
      </Title>
      <Paragraph>
        La normativa aplicable te confiere ciertos derechos sobre tu información
        personal, los cuales podrás consultar según se especifica en el anexo de
        cada país, como por ejemplo: <br />
        • Acceso. <br />
        • Actualización. <br />
        • Rectificación. <br />
        • El cese en el envío de publicidades, ofertas y promociones. <br />
        • Supresión. <br />
        • Revocación del consentimiento. <br />
        • Confidencialidad. <br />
        • Revisión de decisiones automatizadas. <br />
        En determinados casos, y siempre que así lo permita o lo imponga la
        legislación aplicable, mantendremos en nuestros archivos la información
        personal que nos hayas pedido que suprimamos por un plazo limitado en el
        tiempo. Una vez cumplido ese plazo, procederemos a suprimir tu
        información personal. <br />
      </Paragraph>
      <Title level={3}>14. Cookies y Otras Tecnologías</Title>
      <Paragraph>
        Reconoces y aceptas expresamente que Blizu podrá utilizar un sistema de
        seguimiento de conducta mediante la utilización de "cookies" y/u otras
        tecnologías similares de seguimiento.
        <br />
        Estas tecnologías se utilizan con el fin de conocer los intereses y el
        comportamiento de quienes visitan o son usuarios de nuestro sitio web y,
        de esa forma, darles un mejor servicio o proveerles información
        relacionada. También, usamos la información obtenida a través de cookies
        para analizar las páginas navegadas por el visitante o usuario, las
        búsquedas realizadas, mejorar nuestras iniciativas comerciales y
        promocionales, mostrar publicidad o promociones, banners de interés,
        noticias sobre Blizu, perfeccionar y personalizar nuestra oferta de
        contenidos y artículos, presentación y servicios, así como promover y
        hacer cumplir las reglas y seguridad del sitio; también las utilizamos
        para que el usuario no tenga que introducir su clave tan frecuentemente
        durante una sesión de navegación, también para contabilizar y corroborar
        las inscripciones, la actividad del usuario y otros conceptos para
        acuerdos comerciales, siempre teniendo como objetivo de la instalación
        de las cookies, el beneficio del usuario que la recibe, y las cuales no
        serán usadas con fines ajenos a Blizu. Así mismo, almacenamos cookies
        para poder ofrecer una experiencia más interactiva en el sitio, basada
        en las acciones del usuario.
        <br />
        Tus datos personales obtenidos a través de estas tecnologías no serán
        transferidos a terceros de una manera diferente a las descritas en esta
        Declaración de Privacidad.
        <br />
        Adicionalmente, se pueden encontrar "cookies" u otros sistemas similares
        instalados por terceros en ciertas páginas de nuestros sitios web o
        utilizados por anunciantes ajenos a Blizu. <br />
        Ten en cuenta que la instalación, permanencia y existencia de las
        cookies en tu computadora o dispositivo depende de tu exclusiva voluntad
        y pueden ser eliminadas cuando así lo desees desde tu navegador.
        <br />
        En este sentido, puedes en cualquier momento eliminar las cookies
        almacenadas, configurar el navegador para que solicite aprobación antes
        de almacenar cookies o directamente evitar que se almacenen cookies.
        Este procedimiento se realiza de forma diferente en los distintos
        navegadores y es necesario realizarlo en cada navegador que utilices.{" "}
        <br />
        Ten en cuenta que si desactivas las cookies, es posible que no puedas
        hacer uso de todas las funcionalidades que ofrece Blizu.
        <br />
      </Paragraph>
      <Title level={3}>
        15. Seguridad. Almacenamiento de la información personal
      </Title>
      <Paragraph>
        Blizu cumple con la normativa y ha adoptado medidas de seguridad
        siguiendo los estándares de la industria para proteger tu información
        personal. <br />
        En la medida en que Blizu haya cumplido con las normas y adoptado las
        medidas mencionadas en el apartado anterior, no se hace responsable por
        interceptaciones ilegales o violaciones de sus sistemas o bases de
        datos, ni por su utilización por parte de personas no autorizadas. Blizu
        tampoco se hace responsable por la indebida utilización de la
        información obtenida por esos medios. <br />
      </Paragraph>
      <Title level={3}>16. Cambios en la Declaración de Privacidad </Title>
      <Paragraph>
        Blizu realizará actualizaciones periódicas de la Declaración de
        Privacidad para reflejar los cambios constantes en los servicios que
        ofrecemos. Estas actualizaciones reflejarán de manera transparente la
        forma en que la información personal es tratada. Te notificaremos de
        estos cambios por nuestros canales habituales, como el correo
        electrónico o mensajes a través de las aplicaciones. En los casos que
        corresponda, recabaremos tu consentimiento.
      </Paragraph>
      <Title level={3}>17. Ley Aplicable y Jurisdicción</Title>
      <Paragraph>
        La Declaración de Privacidad se regirá por las leyes aplicables conforme
        se detalla en los anexos de cada país incluídos debajo. Ante cualquier
        controversia o divergencia relacionada con la interpretación, validez,
        celebración o cumplimiento de la presente Declaración de Privacidad, se
        resolverá por los tribunales competentes indicados en dichos anexos de
        cada país incluidos debajo.
      </Paragraph>
      <br />
      <br />
      <Title level={3}>ANEXO PARA MÉXICO</Title>
      <Paragraph>
        El tratamiento de datos personales que se realice en México, o al que
        aplique el derecho mexicano, se regirá de conformidad con lo establecido
        en la Declaración de Privacidad y este Anexo para México (“Anexo
        México”). La Declaración de Privacidad y este Anexo México constituyen
        el aviso de privacidad integral en términos de la normativa mexicana. En
        caso de conflicto entre la Declaración de Privacidad y el Anexo México,
        este último prevalecerá.
      </Paragraph>
      <Title level={3}>
        ¿Quién es el responsable del tratamiento de la información personal?
      </Title>
      <Paragraph>
        • Para los servicios disponibles en la Plataforma operados por Blizu
        Shop SAPI de C.V., esta es la responsable del tratamiento. <br />
        Dichos Servicios son ofrecidos a través de la Plataforma
        www.blizu.com.mx, Teniendo su domicilio para recibir y oír
        notificaciones en Av. Juan Rulfo 1851 - 69, Fracc. Sonterra, C.P. 76235,
        Querétaro, Qro, México.
      </Paragraph>
      <Title level={3}>
        ¿Qué Información Personal recolectamos y tratamos?
      </Title>
      <Paragraph>
        Para las finalidades mencionadas en la Declaración de Privacidad
        podremos recabar los datos personales expresamente ahí señalados, así
        como cualquier otro dentro de las siguientes categorías: (I)
        identificación; (II) contacto; (III) financieros; (IV) intereses y
        preferencias. <br />
        Para los datos personales de terceros que nos llegues a proporcionar,
        deberás contar previamente con el consentimiento del titular de dichos
        datos, e informarle que puede conocer este aviso de privacidad. En
        cualquier caso, Blizu cumplirá con las disposiciones de la Ley Federal
        de Protección de Datos Personales en Posesión de los Particulares
        (“LFPDPPP”) y su Reglamento en cuanto al tratamiento de datos personales
        obtenidos de forma indirecta. <br />
      </Paragraph>
      <Title level={3}>¿Qué hacemos con la información personal?</Title>
      <Paragraph>
        Tus datos personales son recabados para las finalidades principales y
        secundarias mencionadas en la Declaración de Privacidad. Las finalidades
        primarias son las que se señalan en la Sección 4, numerales 1 a 6, de la
        Declaración de privacidad, y la finalidad secundaria es la mencionada en
        el numeral 7.
        <br />
        Si no deseas que tus datos personales sean tratados para la finalidad
        secundaria mencionada, puedes negarnos tu consentimiento desde este
        momento enviando tu solicitud a través nuestros medios de contacto. Tu
        negativa en este sentido no afectará la realización de las demás
        finalidades establecidas en la Declaración de Privacidad.
        <br />
      </Paragraph>
      <Title level={3}>¿Cómo compartimos la información personal?</Title>
      <Paragraph>
        Tus datos personales podrán ser transferidos, sin que legalmente se
        requiera tu consentimiento, a las personas físicas y/o morales señaladas
        en la Sección 5 de la Declaración de Privacidad, con excepción de las
        transferencias señaladas en el inciso (II). En este último caso,
        requerimos tu consentimiento para poder transferir tus datos personales
        de identificación y contacto. Si no manifiestas tu negativa para que
        realicemos esta transferencia, entenderemos que nos has otorgado tu
        consentimiento. Tu negativa la puedes manifestar en cualquier momento.
      </Paragraph>
      <Title level={3}>
        ¿Cómo puedes ejercer tus derechos para controlar tu información
        personal?
      </Title>
      <Paragraph>
        Además del medio dispuesto en la Sección 13 de la Declaración de
        Privacidad, también podrás realizar consultas y/o ejercer los derechos
        de acceso, rectificación, cancelación y oposición al tratamiento de tu
        Información Personal, así como revocar tu consentimiento en los términos
        permitidos por la normativa aplicable, mediante una solicitud dirigida a
        nuestro Departamento de Datos Personales por correo cualquiera de los
        siguientes medios: <br />
        Por correo al siguiente domicilio: Av. Juan Rulfo 1851 - 69, Fracc.
        Sonterra, C.P. 76235, Querétaro, México. <br />
        Tu solicitud de consulta deberá contener tu nombre y domicilio para
        comunicarte la respuesta, y en caso de que desees ejercer al algún
        derecho, tu solicitud deberá contener: <br />
        • Tu nombre, domicilio u otro medio para comunicarle la respuesta a tu
        solicitud, <br />
        • Documentos que acrediten tu identidad o, en su caso, la representación
        legal que otorgaste, <br />
        • Descripción clara y precisa de los datos personales respecto de los
        derechos que buscas ejercer. <br />
        • Cualquier otro elemento o documento que facilite la localización de
        tus datos personales. <br />
        Responderemos a tu solicitud dentro de los 20 (veinte) días hábiles
        siguientes a la fecha en que sea recibida. Si tu solicitud es
        procedente, la haremos efectiva dentro de los 15 (quince) días hábiles
        siguientes a la fecha en que comuniquemos la respuesta. Si tu solicitud
        está incompleta o incorrecta, pediremos que la corrijas, contando con 10
        (diez) días hábiles para hacerlo. El uso de medios electrónicos nos
        autoriza para atender tu solicitud a través del mismo medio, salvo que
        indiques otro medio de contacto en tu solicitud. <br />
        Podrás obtener la información o datos personales solicitados a través de
        copias simples, documentos electrónicos en formatos convencionales
        (Word, PDF, etc.), o a través de cualquier otro medio legítimo que
        garantice y acredite el ejercicio efectivo del derecho solicitado.{" "}
        <br />
        El derecho de cancelación no es absoluto. Por favor toma en cuenta que
        debemos conservar información para cumplir con diversas obligaciones
        reglamentarias o legales y durante el período de prescripción legal de
        posibles responsabilidades legales o contractuales; y que para hacerlo
        podríamos compartir datos personales con otras entidades u organismos.
        En tales casos, es posible que el derecho de cancelación deba
        solicitarse ante la entidad que recibió los datos personales. <br />
      </Paragraph>

      <Title level={3}>Ley Aplicable y Jurisdicción</Title>
      <Paragraph>
        La Declaración de Privacidad y el presente Anexo México se regirán por
        las leyes de los Estados Unidos Mexicanos. Ante cualquier controversia o
        divergencia relacionada con la interpretación, validez o cumplimiento de
        estas, tú y Blizu declaran que se someten a la jurisdicción exclusiva de
        las autoridades competentes de México.
      </Paragraph>
      <br />
    </div>
  );
};

export default DeclaracionPrivacidad;
