import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { List, Card, Typography, Button, message, Alert } from "antd";

const { Title, Paragraph } = Typography;

const AdminAprobarDatosPago = () => {
  const [loading, setLoading] = useState(true);
  const [vendedoresPendientes, setVendedoresPendientes] = useState([]);

  useEffect(() => {
    const fetchVendedoresPendientes = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "datosPagoVendedor"),
            where("estado", "==", "pendiente")
          )
        );

        const vendedoresConNombres = [];

        for (const doc of querySnapshot.docs) {
          const vendedor = { id: doc.id, ...doc.data() };
          const nombreTienda = await obtenerNombreTienda(doc.id);
          const nombreUsuario = await obtenerNombreUsuario(doc.id);
          vendedor.nombreTienda = nombreTienda;
          vendedor.nombreUsuario = nombreUsuario;
          vendedoresConNombres.push(vendedor);
        }
        //      console.log(vendedoresConNombres);
        setVendedoresPendientes(vendedoresConNombres);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener los datos de pago pendientes:", error);
        setLoading(false);
      }
    };

    fetchVendedoresPendientes();
  }, []);

  const obtenerNombreTienda = async (id) => {
    try {
      const docSnap = await getDoc(doc(db, "tiendas", id));
      if (docSnap.exists()) {
        return docSnap.data().nombre;
      } else {
        return "Nombre de tienda no encontrado";
      }
    } catch (error) {
      console.error("Error al obtener el nombre de la tienda:", error);
      return "Nombre de tienda no encontrado";
    }
  };

  const obtenerNombreUsuario = async (id) => {
    try {
      const docSnap = await getDoc(doc(db, "roles", id));
      if (docSnap.exists()) {
        return docSnap.data().username;
      } else {
        return "Nombre de usuario no encontrado";
      }
    } catch (error) {
      console.error("Error al obtener el nombre de usuario:", error);
      return "Nombre de usuario no encontrado";
    }
  };

  const aprobarDatosPago = async (vendedorId) => {
    try {
      await updateDoc(doc(db, "datosPagoVendedor", vendedorId), {
        estado: "aprobado",
        comentariosRechazo: "",
      });
      message.success("Datos de pago aprobados");
      setVendedoresPendientes((prev) =>
        prev.filter((vendedor) => vendedor.id !== vendedorId)
      );
    } catch (error) {
      console.error("Error al aprobar los datos de pago:", error);
      message.error("Hubo un error al aprobar los datos de pago");
    }
  };

  const rechazarDatosPago = async (vendedorId) => {
    const comentariosRechazo = prompt(
      "Por favor, ingrese los comentarios de rechazo:"
    );
    if (!comentariosRechazo) return;

    try {
      await updateDoc(doc(db, "datosPagoVendedor", vendedorId), {
        estado: "rechazado",
        comentariosRechazo,
      });
      message.success("Datos de pago rechazados");
      setVendedoresPendientes((prev) =>
        prev.filter((vendedor) => vendedor.id !== vendedorId)
      );
    } catch (error) {
      console.error("Error al rechazar los datos de pago:", error);
      message.error("Hubo un error al rechazar los datos de pago");
    }
  };

  if (loading) {
    return <p>Cargando datos de pago pendientes...</p>;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card className="container" style={{ margin: "20px", maxWidth: "600px" }}>
        <Title level={2}>Aprobar o rechazar datos de pago </Title>
        <Paragraph>
          Revise y apruebe o rechace los datos de pago de los vendedores
          pendientes.
        </Paragraph>
        {vendedoresPendientes.length === 0 ? (
          <Alert
            message="No hay datos de pago pendientes"
            type="info"
            showIcon
          />
        ) : (
          <List
            itemLayout="vertical"
            dataSource={vendedoresPendientes}
            renderItem={(vendedor) => (
              <List.Item key={vendedor.id}>
                <Card>
                  <List.Item.Meta
                    description={
                      <>
                        <Paragraph>
                          <strong>Nombre de tienda:</strong>{" "}
                          {vendedor.nombreTienda}
                        </Paragraph>
                        <Paragraph>
                          <strong>Nombre de usuario:</strong>{" "}
                          {vendedor.nombreUsuario}
                        </Paragraph>
                        <Paragraph>
                          <strong>Nombre:</strong> {vendedor.nombre}
                        </Paragraph>
                        <Paragraph>
                          <strong>Clabe o tarjeta:</strong> {vendedor.clabe}
                        </Paragraph>
                        <Paragraph>
                          <strong>Banco:</strong> {vendedor.banco}
                        </Paragraph>
                        <Button type="link" block>
                          <a
                            href={vendedor.estadoCuenta}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ver/Descargar estado de cuenta cargado
                          </a>
                        </Button>
                      </>
                    }
                  />
                  <Button
                    type="primary"
                    style={{
                      width: "200px",
                      backgroundColor: "#1dbe72",
                      marginRight: "10px",
                    }}
                    onClick={() => aprobarDatosPago(vendedor.id)}
                  >
                    Aprobar
                  </Button>
                  <Button danger onClick={() => rechazarDatosPago(vendedor.id)}>
                    Rechazar
                  </Button>
                </Card>
              </List.Item>
            )}
          />
        )}
      </Card>
    </div>
  );
};

export default AdminAprobarDatosPago;
