import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Menu,
  Dropdown,
  Avatar,
  message,
  Space,
  Drawer,
  Badge,
} from "antd";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import logo from "../../assets/logo.png";
import Buscador from "../Buscador/Buscador";
import BtnCarrito from "../BtnCarrito";
import BtnFavoritos from "../BtnFavoritos";
import { useNavigate } from "react-router-dom";
import "./NavbarStyle.scss";
import {
  DownOutlined,
  MenuOutlined,
  UserOutlined,
  ShopOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import {
  fetchOrdersAndPendingMessages,
  fetchOrdersAndPendingMessagesVendedor,
} from "../../utils/ordenes/ordenesUtils";

const Navbar = () => {
  const { userData, clearUser, userFavorites, cartItems } = useUser();
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [totalPendingMessages, setTotalPendingMessages] = useState(0); // Estado para contar mensajes pendientes
  const [totalMessagesNav, setTotalMessagesNav] = useState(0);
  const [totalPendingMessagesVendedor, setTotalPendingMessagesVendedor] =
    useState(0);

  const handleLogout = () => {
    Modal.confirm({
      title: "Cerrar sesión",
      content: "¿Estás seguro de que deseas cerrar sesión?",
      okText: "Sí",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        try {
          // Lógica para cerrar sesión
          clearUser();
          onClose();
          message.success("Se ha cerrado la sesión exitosamente");
          navigate("/login");
        } catch (error) {
          message.error("Error al cerrar la sesión");
        }
      },
    });
  };
  const onClose = () => {
    setDrawerVisible(false);
  };
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/perfil">
          Ir a perfil{" "}
          {totalPendingMessages > 0 && (
            <Badge
              count={totalPendingMessages}
              style={{
                marginRight: "8px",
                backgroundColor: "#f5222d",
              }} // Añade color rojo al badge
            />
          )}{" "}
        </Link>
      </Menu.Item>

      {/* Mostrar el botón "Ir a mi tienda" solo si el role es igual a 1 */}
      {userData && userData.role === 1 && (
        <Menu.Item key="3">
          <Link to="/dashboard">Ir a mi panel de vendedor </Link>
          {totalPendingMessagesVendedor > 0 && (
                  <Badge
                    count={totalPendingMessagesVendedor}
                    style={{
                      marginRight: "8px",
                      backgroundColor: "#f5222d",
                    }} // Añade color rojo al badge
                  />
                )}
        </Menu.Item>
      )}
      {/* Mostrar el botón "Ir a mi panel admin" solo si el role es igual a 2 */}
      {userData && userData.role === 2 && (
        <Menu.Item key="3">
          <Link to="/admin">Ir a mi panel administrador</Link>
        </Menu.Item>
      )}
      <Menu.Item key="2" onClick={handleLogout}>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const fetchOrdersAndMessages = async () => {
      if (userData) {
        try {
          const { totalMessages } = await fetchOrdersAndPendingMessages(
            userData.uid
          );
          setTotalPendingMessages(totalMessages); // Actualiza el estado con el conteo total de mensajes
          setTotalMessagesNav(totalMessagesNav + totalMessages);
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      }
    };

    fetchOrdersAndMessages();

    const fetchOrdersAndMessagesVendedor = async () => {
      if (userData && userData.role == 1) {
        try {
          const { totalMessages } = await fetchOrdersAndPendingMessagesVendedor(
            userData.uid
          );
          setTotalPendingMessagesVendedor(totalMessages); // Actualiza el estado con el conteo total de mensajes
          setTotalMessagesNav(totalMessagesNav + totalMessages);
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      }
    };

    fetchOrdersAndMessagesVendedor();


  }, [userData]);

  return (
    <>
      <div className="styled-nav2">
        <Link to="/">
          <img src={logo} className="logoNav" alt="blizu" />
        </Link>

        <Buscador />

        <Button
          icon={<MenuOutlined />}
          onClick={() => setDrawerVisible(true)}
          className="drawer-optional"
        />

        <div className="nav-optional">
          <>
            {userData && userData.uid && typeof userData === "object" ? (
              <Space>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Button>
                    <Space>
                      <Avatar
                        src={userData.photo}
                        style={{ width: "25px", height: "25px" }}
                        alt="User Photo"
                      />
                      <span className="user-name">
                        {userData &&
                        userData.username &&
                        userData.username.length > 20
                          ? userData.username.slice(0, 20) + "..."
                          : userData && userData.username}
                      </span>
                      {totalMessagesNav > 0 && (
                        <Badge
                          count={totalMessagesNav}
                          style={{
                            marginRight: "8px",
                            backgroundColor: "#f5222d",
                          }} // Añade color rojo al badge
                        />
                      )}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
                <BtnCarrito
                  cartItems={cartItems}
                  disabled={!userData}
                  text={false}
                />
                <BtnFavoritos
                  favoriteItems={userFavorites}
                  disabled={!userData}
                  text={false}
                />
              </Space>
            ) : (
              <div className="login-actions">
                <Link to="/login">
                  <Button type="primary">Iniciar sesión</Button>
                </Link>
                <Link to="/tipo">
                  <Button type="link">Registrarse</Button>
                </Link>
              </div>
            )}
          </>
        </div>
      </div>

      <Drawer
        title="Menú"
        placement="left"
        closable={false}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={300}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }} // Centra el Drawer
      >
        <div className="drawer-content">
          {userData && userData.uid && typeof userData === "object" ? (
            <Space direction="vertical" align="center">
              <Avatar src={userData.photo} alt="User" />
              <span className="user-name">
                {userData && userData.username && userData.username.length > 20
                  ? userData.username.slice(0, 20) + "..."
                  : userData && userData.username}
              </span>
              <span className="user-email">
                {userData && userData.email}{" "}
                {/* Asumiendo que userData tiene una propiedad 'email' */}
              </span>
              <BtnCarrito
                cartItems={cartItems}
                disabled={!userData}
                text={true}
                onClick={onClose}
              />
              <BtnFavoritos
                favoriteItems={userFavorites}
                disabled={!userData}
                text={true}
                onClick={onClose}
              />
              <Link to="/perfil" onClick={onClose}>
                <Button icon={<UserOutlined />}>Ir a mi perfil</Button>{" "}
                {totalPendingMessages > 0 && (
                  <Badge
                    count={totalPendingMessages}
                    style={{
                      marginRight: "8px",
                      backgroundColor: "#f5222d",
                    }} // Añade color rojo al badge
                  />
                )}
              </Link>
              {/* Mostrar el botón "Ir a mi tienda" solo si el role es igual a 1 */}
              {userData && userData.role === 1 && (
                <Link to="/dashboard">
                  <Button icon={<ShopOutlined />}>
                    Ir a mi panel de vendedor
                  </Button>
                </Link>
              )}
              {/* Mostrar el botón "Ir a mi panel admin" solo si el role es igual a 2 */}
              {userData && userData.role === 2 && (
                <Link to="/admin">Ir a mi panel administrador</Link>
              )}
              <Button icon={<RollbackOutlined />} onClick={handleLogout}>
                Cerrar sesión
              </Button>
            </Space>
          ) : (
            <div className="drawer-login-actions">
              <Link to="/login" onClick={onClose}>
                <Button type="primary">Iniciar sesión</Button>
              </Link>

              <Link to="/tipo">
                <Button type="link">Registrarse</Button>
              </Link>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default Navbar;
