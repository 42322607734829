// TerminosCondiciones.jsx

import React from "react";
import { Typography } from "antd";
import "./TerminosMarketplace.css";
const { Title, Paragraph } = Typography;

const TerminosMarketplace = () => {
  return (
    <div className="terminos-market-container">
      <Title level={2}>Terminos y condiciones del vendedor</Title>

      <Title level={3}>Qué es el Marketplace</Title>
      <Paragraph>
        Es la plataforma de comercio electrónico de Blizu, en donde las Personas
        Usuarias pueden vender, comprar y alquilar productos y servicios usando
        distintas soluciones de pago y envío.
      </Paragraph>

      <Title level={3}>Anexo</Title>
      <Paragraph>
        Este anexo explica las reglas de uso del Marketplace para todas las
        Personas Usuarias, sean quienes vendan (en adelante, “Vendedores”) y/o
        quienes compran (en adelante, “Compradores”).
      </Paragraph>
      <Title level={3}>1. Vender</Title>
      <Title level={4}>a. Obligaciones del Vendedor</Title>
      <Paragraph>
        Para vender un producto en Marketplace es necesario que el Vendedor
        cumpla con todos los requisitos y permisos que exige la ley.
      </Paragraph>
      <Paragraph>
        Respecto a cada Comprador, el Vendedor se obliga a: • Respetar las
        condiciones de venta publicadas en el Sitio. • Emitir la factura o
        ticket correspondiente, salvo que el Vendedor sea una persona física que
        efectúe ventas ocasionales o esté eximida de la obligación de emisión de
        facturas. • Garantizar el derecho de retracto o revocación de la compra
        dentro del plazo legal. • Respetar las garantías legales aplicables,
        según el caso. • Asumir los costos de la revocación o devolución de los
        productos.
      </Paragraph>
      <Paragraph>
        En caso de la devolución del producto, Blizu podrá poner a disposición
        del Comprador el método que considere más adecuado para facilitar el
        envío y debitará su costo de la cuenta del Vendedor. El Vendedor acepta
        usar únicamente los medios de pago que Blizu pone a su disposición para
        garantizar la seguridad de las operaciones y el funcionamiento de los
        programas de protección.
      </Paragraph>

      <Title level={4}>b. Autorizaciones otorgadas a Blizu.</Title>
      <Paragraph>
        El Vendedor otorga a Blizu y sus sociedades relacionadas una licencia
        gratuita, sin límite de tiempo, internacional y no exclusiva sobre todos
        los contenidos incluidos en sus publicaciones, tales como, los nombres
        comerciales, marcas, imágenes, textos y todo otro elemento o signo
        distintivo que identifique al Vendedor o a los productos o servicios
        publicados en el Marketplace (“Materiales”). En particular, Blizu podrá
        utilizar, publicar, reproducir, poner a disposición, traducir y
        modificar los Materiales para:
      </Paragraph>
      <Paragraph>
        • Usarlos en todos los sitios de Blizu y cualquier medio de comunicación
        con el fin de clasificar productos, identificar ofertas y realizar
        acciones publicitarias y de marketing. • Crear publicaciones de
        Catálogo. • Autorizar su uso a terceros.
      </Paragraph>
      <Paragraph>
        Esta autorización no implica la cesión de los derechos de propiedad
        intelectual que pudieran existir sobre los Materiales. El Vendedor
        declara que tiene todos los derechos necesarios para autorizar a Blizu
        para tal efecto, que es responsable exclusivo de cualquier infracción a
        derechos de terceros que el uso de los Materiales pueda ocasionar, y que
        se compromete a liberar a Blizu de cualquier reclamo que pudiera recibir
        de terceros derivados del uso de ese Material. Blizu también podrá a su
        criterio restringir publicaciones de acuerdo a la geolocalización de la
        persona que esté haciendo la búsqueda así como de otros factores que
        determine relevantes con el objetivo de mejorar la experiencia de
        compra.
      </Paragraph>

      <Title level={4}>c. Tarifas y Facturación</Title>
      <Paragraph>
        El vendedor deberá pagar un costo por los servicios informáticos que
        presta Blizu.
      </Paragraph>

      <Title level={4}>d. Reputación</Title>
      <Paragraph>
        Cada Vendedor cuenta con un Sistema de Reputación que es actualizado
        periódicamente en base a datos que surgen de su actividad en el Sitio.
        Este sistema tendrá además un espacio donde los Compradores podrán
        opinar sobre el Vendedor y/o el producto adquirido. Dichos comentarios
        serán incluidos bajo exclusiva responsabilidad de quien lo emita. Blizu
        podrá editar y/o eliminar aquellos comentarios que sean considerados
        inadecuados u ofensivos.
      </Paragraph>

      <Title level={4}>e. Obligaciones adicionales</Title>
      <Paragraph>
        Para verificar que los Compradores del Marketplace estén recibiendo una
        excelente experiencia de compra, con una oferta amplia de productos de
        calidad y a precios competitivos, desde Blizu revisaremos periódicamente
        la oferta de los Vendedores que tengan una presencia importante en el
        mercado local y/o sean titulares o licenciatarios de marcas reconocidas
        nacional y/o internacionalmente . Con el fin de resguardar la
        experiencia de los Compradores y el éxito del Marketplace en la
        democratización del comercio, si identificamos que están ofreciendo una
        experiencia de compra deficiente, una oferta de productos muy acotada
        y/o precio que no son suficientemente competitivos en relación a otros
        canales de venta comparables por tipo o categoría de producto (por
        ejemplo, sitios web propios, redes sociales, locales físicos y otras
        plataformas en línea), desde Blizu podremos subsanar esa brecha de
        oferta y precio. En ese caso, podremos disponer un plazo para que los
        Vendedores puedan subsanar estas deficiencias en forma directa y,
        además, ofrecer la posibilidad de convertirse en nuestros Proveedores
        para que nos ofrezcan directamente la más amplia oferta de productos que
        permitan su reventa al público a precios competitivos. Si el Vendedor no
        acepta esta posibilidad y su oferta continúa siendo deficiente,
        podríamos dar de baja su cuenta y/o dejar sin efecto las funcionalidades
        que están destinadas a destacar exclusivamente a los vendedores que
        mejor experiencia de compra ofrecen a las Personas Usuarias (ejemplo,
        Tiendas Oficiales, Brand Stores)
      </Paragraph>

      <Title level={3}>2. Comprar</Title>
      <Paragraph>
        El Comprador podrá encontrar en la publicación toda la información que
        el Vendedor proporcione en relación al producto a ofertar. También podrá
        hacer uso de la sección de “Envía un mensaje al vendedor” para resolver
        cualquier duda en relación al producto, sus características, modalidades
        de entrega, etc. Blizu recomienda al Comprador la lectura atenta de
        etiquetas, advertencias, instrucciones y certificados de garantía antes
        de utilizar o consumir el producto. Finalizada la operación, el
        Comprador deberá recibir el producto, teniendo la posibilidad de dejar
        su opinión sobre el producto y/o sobre el Vendedor. Ante cualquier
        inconveniente, el Comprador podrá iniciar un reclamo en el Sitio contra
        el Vendedor, dentro del plazo de 60 (sesenta) días corridos posteriores
        a la compra.
      </Paragraph>

      <Title level={3}>3. Prohibiciones y Sanciones</Title>
      <Paragraph>
        Las Personas Usuarias no podrán: a) Incumplir cualquier disposición de
        los Términos y Condiciones de Blizu o de la normativa aplicable. b)
        Mantener cualquier tipo de comunicación por otro medio distinto al
        servicio de mensajería que ofrece Blizu (incluyendo mails, redes
        sociales, WhatsApp, etc.) ya sea durante la oferta del producto o
        servicio y hasta el vencimiento del plazo de 60 días corridos,
        posteriores a la compra. c) Usar su reputación, calificaciones o
        comentarios recibidos en el sitio de Blizu en cualquier ámbito fuera de
        Blizu.
      </Paragraph>
      <Paragraph>
        De detectarse alguna infracción, y sin perjuicio de las sanciones
        dispuestas en los Términos y Condiciones Generales, Blizu podrá
        suspender o finalizar las publicaciones en cuestión, suspender o
        inhabilitar a las Personas Usuarias.
      </Paragraph>

      <Title level={3}>4. Responsabilidad</Title>
      <Paragraph>
        Blizu pone a disposición de las Personas Usuarias un espacio virtual que
        les permite ofrecer, vender, comprar y alquilar productor y servicios.
        Los Vendedores utilizan Blizu para publicar y definir las condiciones de
        su oferta. Por eso, serán responsables por la existencia, calidad,
        cantidad, regularidad, garantía, integridad y/o legitimidad de los
        productos que ofrecen, y mantendrán indemnes a Blizu por cualquier
        acción administrativa o judicial que pudiera generarse con motivo de una
        oferta o venta.
      </Paragraph>
    </div>
  );
};

export default TerminosMarketplace;
