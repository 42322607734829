import React, { useState } from "react";
import { Modal, Button } from "antd";
import { EnvironmentOutlined, WarningOutlined } from "@ant-design/icons"; // Iconos de Ant Design
import MapGoogle from "../MapGoogle/MapGoogle";

const ModalUbicacion = ({ visible, onOk, onCancel }) => {
  const [markerPosition, setMarkerPosition] = useState(null);
  const [locationAllowed, setLocationAllowed] = useState(false);
  const [error, setError] = useState(null);
  const [locationRequested, setLocationRequested] = useState(false); // Para manejar si se solicitó la ubicación

  // Función para solicitar la ubicación
  const requestLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationAllowed(true);
          setMarkerPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setError(null); // Limpiar cualquier error previo
        },
        (error) => {
          setLocationAllowed(false);
          setError(error.message);
        }
      );
    } else {
      setLocationAllowed(false);
      setError("Geolocalización no es compatible con este navegador.");
    }
    setLocationRequested(true); // Marcamos que se solicitó la ubicación
  };

  const handleMapClick = ({ latLng }) => {
    setMarkerPosition({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });
  };

  return (
    <Modal
      title="Ubicación de productos y servicios"
      visible={visible}
      onOk={() => onOk(markerPosition)}
      okButtonProps={{ disabled: !markerPosition }}
      maskClosable={false}
      cancelButtonProps={{ disabled: true }}
      closable={false}
    >
      {locationAllowed ? (
        <>
        
          <MapGoogle
            onMapClick={handleMapClick}
            markerPosition={markerPosition}
          />
          <p>
            Selecciona una ubicación en el mapa o utiliza el campo de búsqueda
            para encontrar una ubicación específica para encontrar productos o
            servicios.
          </p>
        </>
      ) : (
        <>
          {!locationRequested ? (
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              {/* Mostrar el botón para solicitar ubicación */}
              <EnvironmentOutlined style={{ fontSize: "48px", color: "blue" }} />
              <p style={{ fontSize: "18px", margin: "20px 0" }}>
                Habilita la ubicación para mejorar la experiencia de usuario
              </p>
              <Button
                type="primary"
                onClick={requestLocation}
                icon={<EnvironmentOutlined />}
              >
                Habilitar ubicación
              </Button>
            </div>
          ) : (
            <>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                {/* Icono grande de advertencia */}
                <WarningOutlined style={{ fontSize: "48px", color: "red" }} />
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  marginBottom: "20px",
                }}
              >
                Para utilizar la página web de forma correcta, es necesario que
                habilite la geolocalización en su navegador.
              </p>
              {error && (
                <>
                  {error.includes("denied") && (
                    <p style={{ textAlign: "center", color: "red" }}>
                      Parece que has denegado el acceso a la geolocalización. Para
                      volver a habilitarla, por favor ve a la configuración de tu
                      navegador y permite el acceso a la ubicación.
                    </p>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default ModalUbicacion;
