import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { Button, List, Card, Typography, Flex, Empty, Badge } from "antd";
import { useUser } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import pedidoempty from "../../../assets/empty/logistica.png";
import { MessageOutlined } from "@ant-design/icons";

const Pedidos = () => {
  const [orders, setOrders] = useState([]);
  const { userData } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      if (userData) {
        try {
          const ordersCollection = collection(db, "pedidos");
          const userOrdersQuery = query(
            ordersCollection,
            where("usuarioUid", "==", userData.uid),
            orderBy("fechaCreacion", "desc") // Ordena por fecha de creación descendente
          );

          const ordersSnapshot = await getDocs(userOrdersQuery);

          const ordersData = await Promise.all(
            ordersSnapshot.docs.map(async (doc) => {
              const order = { id: doc.id, ...doc.data() };

              // Obtener el conteo de mensajes pendientes para cada pedido
              const pendingMessagesCount = await getPendingMessagesCount(
                order.id
              );
              return { ...order, pendingMessagesCount };
            })
          );

          setOrders(ordersData);
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      }
    };

    fetchOrders();
  }, [userData]);

  const getPendingMessagesCount = async (orderId) => {
    // Accede a la colección de subpedidos filtrando por pedidoId
    const subpedidosCollection = collection(db, "subpedidos");
    const subpedidosQuery = query(
      subpedidosCollection,
      where("pedidoId", "==", orderId)
    );
    const subpedidosSnapshot = await getDocs(subpedidosQuery);

    let totalPendingMessages = 0;

    // Recorre los subpedidos para contar los mensajes pendientes
    for (const subpedidoDoc of subpedidosSnapshot.docs) {
      const subpedido = subpedidoDoc.data();
      const messagesCollection = collection(
        db,
        "subpedidos",
        subpedidoDoc.id,
        "messages"
      );
      const messagesQuery = query(
        messagesCollection,
        where("status", "==", "approved"),
        where("senderId", "!=", userData.uid),
        where("read", "==", false)
      );
      const messagesSnapshot = await getDocs(messagesQuery);
      totalPendingMessages += messagesSnapshot.size;
    }

    return totalPendingMessages;
  };

  return (
    <Flex
      vertical
      style={{ textAlign: "center", alignItems: "center", marginTop: "20px" }}
      gap={20}
    >
      <Card style={{ width: "100%", margin: "5px" }}>
        <Typography.Title level={2}>Lista de mis pedidos</Typography.Title>
        {orders && orders.length > 0 ? (
          <List
            style={{ textAlign: "left" }}
            itemLayout="horizontal"
            dataSource={orders}
            renderItem={(order) => (
              <Card style={{ marginBottom: "10px" }}>
                <List.Item
                  actions={[
                    <Button
                      onClick={() => navigate("/pedido/" + order.id)}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Ver pedido
                      <MessageOutlined style={{ marginRight: "8px" }} />
                      {order.pendingMessagesCount > 0 && (
                        <Badge
                          count={order.pendingMessagesCount}
                          style={{
                            marginRight: "8px",
                            backgroundColor: "#f5222d",
                          }} // Añade color rojo al badge
                        />
                      )}
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={`Id del pedido: ${order.id}`}
                    description={`$ ${order.totalGeneral}`}
                  />
                  <div>{`${order.fechaCreacion
                    .toDate()
                    .toLocaleString()}`}</div>
                </List.Item>
              </Card>
            )}
          />
        ) : (
          <Empty
            image={pedidoempty}
            description="Aún no se ha realizado ningun pedido"
          />
        )}
      </Card>
    </Flex>
  );
};

export default Pedidos;
